import React, { useState, useEffect } from 'react';
import { Container, Form, Alert, Row, Col, Card } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';

function Correlation() {
  const [strategy1Data, setStrategy1Data] = useState([]);
  const [strategy2Data, setStrategy2Data] = useState([]);
  const [columns1, setColumns1] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [error, setError] = useState('');
  const [normalizedChartData, setNormalizedChartData] = useState([]);
  const [combinedChartData, setCombinedChartData] = useState([]);
  const [correlation, setCorrelation] = useState(null);

  // Estados para as métricas das Estratégias
  const [totalTrades1, setTotalTrades1] = useState(0);
  const [winRate1, setWinRate1] = useState(0);
  const [profitFactor1, setProfitFactor1] = useState(0);

  const [totalTrades2, setTotalTrades2] = useState(0);
  const [winRate2, setWinRate2] = useState(0);
  const [profitFactor2, setProfitFactor2] = useState(0);

  const [totalTradesCombined, setTotalTradesCombined] = useState(0);
  const [winRateCombined, setWinRateCombined] = useState(0);
  const [profitFactorCombined, setProfitFactorCombined] = useState(0);

  const expectedColumns = [
    'Time',
    'Deal',
    'Symbol',
    'Type',
    'Direction',
    'Volume',
    'Price',
    'Order',
    'Commission',
    'Swap',
    'Profit',
    'Balance',
    'Comment',
  ];

  // Função genérica para processar uma planilha
  const processSheet = (rawData, sheetName) => {
    let found = false;
    let jsonData = [];
    let sheetColumns = [];

    for (let i = 0; i < rawData.length; i++) {
      const row = rawData[i];
      const hasAllColumns = expectedColumns.every((col) => row.includes(col));

      if (hasAllColumns) {
        found = true;
        sheetColumns = row;
        jsonData = rawData
          .slice(i + 1)
          .map((dataRow) => {
            const rowObj = {};
            sheetColumns.forEach((col, index) => {
              rowObj[col] = dataRow[index] || '';
            });
            return rowObj;
          });

        if (jsonData.length > 2) {
          jsonData = jsonData.slice(1, -1);
        } else {
          setError(
            `A planilha "${sheetName}" não possui dados suficientes para remover o primeiro e o último registro.`
          );
          jsonData = [];
        }

        break;
      }
    }

    if (!found) {
      setError(
        `Nenhuma planilha encontrada com as colunas esperadas na planilha "${sheetName}". Verifique o arquivo e tente novamente.`
      );
      jsonData = [];
    }

    return { jsonData, sheetColumns: found ? sheetColumns : [] };
  };

  // Função para lidar com o upload da Estratégia 1
  const handleFileUpload1 = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('Nenhum arquivo selecionado para a Estratégia 1.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      let workbook;

      try {
        workbook = XLSX.read(data, { type: 'array' });
      } catch (err) {
        setError('Erro ao ler o arquivo Excel da Estratégia 1.');
        return;
      }

      if (workbook.SheetNames.length < 1) {
        setError('O arquivo da Estratégia 1 deve conter pelo menos uma planilha.');
        return;
      }

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const { jsonData, sheetColumns } = processSheet(rawData, sheetName);

      if (jsonData.length > 0) {
        setColumns1(sheetColumns);
        setStrategy1Data(jsonData);
        setError('');
      } else {
        setStrategy1Data([]);
        setColumns1([]);
      }
    };

    reader.onerror = () => {
      setError('Erro ao ler o arquivo da Estratégia 1.');
      reader.abort();
    };

    reader.readAsArrayBuffer(file);
  };

  // Função para lidar com o upload da Estratégia 2
  const handleFileUpload2 = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('Nenhum arquivo selecionado para a Estratégia 2.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      let workbook;

      try {
        workbook = XLSX.read(data, { type: 'array' });
      } catch (err) {
        setError('Erro ao ler o arquivo Excel da Estratégia 2.');
        return;
      }

      if (workbook.SheetNames.length < 1) {
        setError('O arquivo da Estratégia 2 deve conter pelo menos uma planilha.');
        return;
      }

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const { jsonData, sheetColumns } = processSheet(rawData, sheetName);

      if (jsonData.length > 0) {
        setColumns2(sheetColumns);
        setStrategy2Data(jsonData);
        setError('');
      } else {
        setStrategy2Data([]);
        setColumns2([]);
      }
    };

    reader.onerror = () => {
      setError('Erro ao ler o arquivo da Estratégia 2.');
      reader.abort();
    };

    reader.readAsArrayBuffer(file);
  };

  // Função para calcular o Coeficiente de Correlação de Pearson
  const calculatePearsonCorrelation = (x, y) => {
    const n = x.length;
    const sum_x = x.reduce((a, b) => a + b, 0);
    const sum_y = y.reduce((a, b) => a + b, 0);
    const sum_xy = x.reduce((acc, val, idx) => acc + val * y[idx], 0);
    const sum_x2 = x.reduce((acc, val) => acc + val * val, 0);
    const sum_y2 = y.reduce((acc, val) => acc + val * val, 0);

    const numerator = n * sum_xy - sum_x * sum_y;
    const denominator = Math.sqrt(
      (n * sum_x2 - sum_x * sum_x) * (n * sum_y2 - sum_y * sum_y)
    );

    if (denominator === 0) return 0;

    return numerator / denominator;
  };

  // Função para processar os dados para os gráficos e métricas
  const processChartData = () => {
    // Processar dados normalizados para as estratégias individuais
    const processStrategyNormalized = (strategy) => {
      return strategy.map((deal) => {
        const balance = parseFloat(deal.Balance);
        return {
          time: deal.Time || '',
          balance: isNaN(balance) ? 0 : balance,
        };
      });
    };

    // Processar dados acumulados para o gráfico combinado
    const processStrategyAccumulated = (strategy) => {
      return strategy.map((deal) => {
        const profit = parseFloat(deal.Profit);
        return {
          time: deal.Time || '',
          profit: isNaN(profit) ? 0 : profit,
        };
      });
    };

    const normalizedData1 = processStrategyNormalized(strategy1Data);
    const normalizedData2 = processStrategyNormalized(strategy2Data);

    const accumulatedData1 = processStrategyAccumulated(strategy1Data);
    const accumulatedData2 = processStrategyAccumulated(strategy2Data);

    if (normalizedData1.length === 0 || normalizedData2.length === 0) {
      setError('Uma das estratégias não possui dados válidos para processar.');
      return;
    }

    // Obter o valor inicial para normalização
    const initial1 = normalizedData1[0].balance;
    const initial2 = normalizedData2[0].balance;

    if (initial1 === 0 || initial2 === 0) {
      setError('O saldo inicial de uma das estratégias é zero, não é possível normalizar.');
      return;
    }

    // Normalizar os saldos
    const normalizedPercData1 = normalizedData1.map((item) => ({
      time: item.time,
      strategy1Percent: ((item.balance / initial1) * 100).toFixed(2),
    }));

    const normalizedPercData2 = normalizedData2.map((item) => ({
      time: item.time,
      strategy2Percent: ((item.balance / initial2) * 100).toFixed(2),
    }));

    // Obter todos os pontos de tempo únicos para normalização
    const allTimesSet = new Set();
    normalizedPercData1.forEach((item) => allTimesSet.add(item.time));
    normalizedPercData2.forEach((item) => allTimesSet.add(item.time));
    const allTimes = Array.from(allTimesSet).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      if (!isNaN(dateA) && !isNaN(dateB)) {
        return dateA - dateB;
      }
      return 0;
    });

    // Identificar o primeiro tempo de cada estratégia
    const firstTime1 = normalizedPercData1.length > 0 ? new Date(normalizedPercData1[0].time) : null;
    const firstTime2 = normalizedPercData2.length > 0 ? new Date(normalizedPercData2[0].time) : null;

    // Função para encontrar o último valor conhecido até um determinado tempo
    const getLastKnown = (data, currentTime, firstTime, dataKey) => {
      const currentDate = new Date(currentTime);
      if (firstTime && currentDate < firstTime) {
        // Antes do início da estratégia, retornar 100%
        return 100;
      }
      const filtered = data.filter((item) => new Date(item.time) <= currentDate);
      if (filtered.length === 0) return 100; // Se nenhuma data anterior, retornar 100%
      return parseFloat(filtered[filtered.length - 1][dataKey] || 100);
    };

    // Construir o conjunto final de dados sincronizados para normalização
    const finalNormalizedData = allTimes.map((time) => {
      const strategy1Record = normalizedPercData1.find((item) => item.time === time);
      const strategy2Record = normalizedPercData2.find((item) => item.time === time);

      const strategy1Percent = strategy1Record
        ? parseFloat(strategy1Record.strategy1Percent)
        : getLastKnown(normalizedPercData1, time, firstTime1, 'strategy1Percent');

      const strategy2Percent = strategy2Record
        ? parseFloat(strategy2Record.strategy2Percent)
        : getLastKnown(normalizedPercData2, time, firstTime2, 'strategy2Percent');

      return {
        time,
        strategy1Percent: isNaN(strategy1Percent) ? 100 : strategy1Percent,
        strategy2Percent: isNaN(strategy2Percent) ? 100 : strategy2Percent,
      };
    });

    // Calcular as somas cumulativas dos Profits para cada estratégia
    const calculateCumulativeProfits = (accumulatedData) => {
      let cumulative = 0;
      return accumulatedData.map((item) => {
        cumulative += item.profit;
        return {
          time: item.time,
          cumulativeProfit: cumulative,
        };
      });
    };

    const cumulativeProfits1 = calculateCumulativeProfits(accumulatedData1);
    const cumulativeProfits2 = calculateCumulativeProfits(accumulatedData2);

    // Obter todos os pontos de tempo únicos para acumulado
    const allTimesAccumulatedSet = new Set();
    cumulativeProfits1.forEach((item) => allTimesAccumulatedSet.add(item.time));
    cumulativeProfits2.forEach((item) => allTimesAccumulatedSet.add(item.time));
    const allTimesAccumulated = Array.from(allTimesAccumulatedSet).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      if (!isNaN(dateA) && !isNaN(dateB)) {
        return dateA - dateB;
      }
      return 0;
    });

    // Identificar o primeiro tempo de cada estratégia para acumulado
    const firstTimeAccumulated1 =
      cumulativeProfits1.length > 0 ? new Date(cumulativeProfits1[0].time) : null;
    const firstTimeAccumulated2 =
      cumulativeProfits2.length > 0 ? new Date(cumulativeProfits2[0].time) : null;

    // Função para encontrar o último valor conhecido de profit até um determinado tempo
    const getLastKnownCumulative = (data, currentTime, firstTime) => {
      const currentDate = new Date(currentTime);
      if (firstTime && currentDate < firstTime) {
        // Antes do início da estratégia, retornar 0
        return 0;
      }
      const filtered = data.filter((item) => new Date(item.time) <= currentDate);
      if (filtered.length === 0) return 0; // Se nenhuma data anterior, retornar 0
      return parseFloat(filtered[filtered.length - 1].cumulativeProfit || 0);
    };

    // Construir o conjunto final de dados sincronizados para acumulado
    const finalCombinedData = allTimesAccumulated.map((time) => {
      const strategy1Record = cumulativeProfits1.find((item) => item.time === time);
      const strategy2Record = cumulativeProfits2.find((item) => item.time === time);

      const strategy1Cumulative = strategy1Record
        ? parseFloat(strategy1Record.cumulativeProfit)
        : getLastKnownCumulative(cumulativeProfits1, time, firstTimeAccumulated1);

      const strategy2Cumulative = strategy2Record
        ? parseFloat(strategy2Record.cumulativeProfit)
        : getLastKnownCumulative(cumulativeProfits2, time, firstTimeAccumulated2);

      const combinedCumulative = strategy1Cumulative + strategy2Cumulative;

      return {
        time,
        combinedCumulative,
      };
    });

    setNormalizedChartData(finalNormalizedData);
    setCombinedChartData(finalCombinedData);

    // Preparar dados para calcular a correlação
    // Precisamos que as duas séries estejam sincronizadas
    const correlationData = finalNormalizedData.filter(
      (item) =>
        item.strategy1Percent !== undefined &&
        item.strategy2Percent !== undefined
    );

    const strategy1Perc = correlationData.map((item) => parseFloat(item.strategy1Percent));
    const strategy2Perc = correlationData.map((item) => parseFloat(item.strategy2Percent));

    // Calcular a correlação
    const pearsonCorrelation = calculatePearsonCorrelation(strategy1Perc, strategy2Perc);
    setCorrelation(pearsonCorrelation.toFixed(4));

    // Calcular Métricas para Estratégia 1
    const filteredTradesStrat1 = strategy1Data.filter((deal) => parseFloat(deal.Profit) > 0 || parseFloat(deal.Profit) < 0);
    const totalTradesStrat1 = filteredTradesStrat1.length;
    const winTradesStrat1 = filteredTradesStrat1.filter((deal) => parseFloat(deal.Profit) > 0).length;
    const winRateStrat1 = totalTradesStrat1 > 0 ? (winTradesStrat1 / totalTradesStrat1) * 100 : 0;
    const sumProfitsStrat1 = filteredTradesStrat1
      .filter((deal) => parseFloat(deal.Profit) > 0)
      .reduce((acc, deal) => acc + parseFloat(deal.Profit), 0);
    const sumLossesStrat1 = filteredTradesStrat1
      .filter((deal) => parseFloat(deal.Profit) < 0)
      .reduce((acc, deal) => acc + parseFloat(deal.Profit), 0);
    const profitFactorStrat1 = sumLossesStrat1 !== 0 ? (sumProfitsStrat1 / Math.abs(sumLossesStrat1)) : 'Infinity';

    setTotalTrades1(totalTradesStrat1);
    setWinRate1(winRateStrat1.toFixed(2));
    setProfitFactor1(profitFactorStrat1 !== 'Infinity' ? profitFactorStrat1.toFixed(2) : profitFactorStrat1);

    // Calcular Métricas para Estratégia 2
    const filteredTradesStrat2 = strategy2Data.filter((deal) => parseFloat(deal.Profit) > 0 || parseFloat(deal.Profit) < 0);
    const totalTradesStrat2 = filteredTradesStrat2.length;
    const winTradesStrat2 = filteredTradesStrat2.filter((deal) => parseFloat(deal.Profit) > 0).length;
    const winRateStrat2 = totalTradesStrat2 > 0 ? (winTradesStrat2 / totalTradesStrat2) * 100 : 0;
    const sumProfitsStrat2 = filteredTradesStrat2
      .filter((deal) => parseFloat(deal.Profit) > 0)
      .reduce((acc, deal) => acc + parseFloat(deal.Profit), 0);
    const sumLossesStrat2 = filteredTradesStrat2
      .filter((deal) => parseFloat(deal.Profit) < 0)
      .reduce((acc, deal) => acc + parseFloat(deal.Profit), 0);
    const profitFactorStrat2 = sumLossesStrat2 !== 0 ? (sumProfitsStrat2 / Math.abs(sumLossesStrat2)) : 'Infinity';

    setTotalTrades2(totalTradesStrat2);
    setWinRate2(winRateStrat2.toFixed(2));
    setProfitFactor2(profitFactorStrat2 !== 'Infinity' ? profitFactorStrat2.toFixed(2) : profitFactorStrat2);

    // Calcular Métricas para o Resultado Acumulado das Estratégias
    const totalTradesCombined = totalTradesStrat1 + totalTradesStrat2;
    const winTradesCombined = winTradesStrat1 + winTradesStrat2;
    const winRateCombined = totalTradesCombined > 0 ? (winTradesCombined / totalTradesCombined) * 100 : 0;
    const sumProfitsCombined = sumProfitsStrat1 + sumProfitsStrat2;
    const sumLossesCombined = sumLossesStrat1 + sumLossesStrat2;
    const profitFactorCombined = sumLossesCombined !== 0 ? (sumProfitsCombined / Math.abs(sumLossesCombined)) : 'Infinity';

    setTotalTradesCombined(totalTradesCombined);
    setWinRateCombined(winRateCombined.toFixed(2));
    setProfitFactorCombined(profitFactorCombined !== 'Infinity' ? profitFactorCombined.toFixed(2) : profitFactorCombined);
  };

  useEffect(() => {
    if (strategy1Data.length > 0 && strategy2Data.length > 0) {
      processChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy1Data, strategy2Data]);

  return (
    <Container className="pt-4 text-light min-vh-100">
      <Row>
        <Col md={6}>
          <Form.Group data-bs-theme="dark" controlId="formFile1" className="mb-3">
            <Form.Label className="text-light">
              Selecione a planilha da Estratégia 1
            </Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload1}
              className="bg-dark text-light border-secondary"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group data-bs-theme="dark" controlId="formFile2" className="mb-3">
            <Form.Label className="text-light">
              Selecione a planilha da Estratégia 2
            </Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload2}
              className="bg-dark text-light border-secondary"
            />
          </Form.Group>
        </Col>
      </Row>

      {error && (
        <Alert variant="danger" data-bs-theme="dark">
          {error}
        </Alert>
      )}

      {strategy1Data.length > 0 && strategy2Data.length > 0 && (
        <>
          {/* Gráfico de Comparação das Estratégias */}
          <h2 className="mt-4">Comparação de Estratégias (Normalizado em %)</h2>
          <ResponsiveContainer width="100%" height={500}>
            <LineChart data={normalizedChartData}>
              <CartesianGrid stroke="#444" strokeDasharray="3 3" />
              <XAxis dataKey="time" stroke="#fff" />
              <YAxis
                stroke="#fff"
                domain={['auto', 'auto']}
                tickFormatter={(tick) => `${tick}%`}
              />
              <Tooltip
                formatter={(value) => `${value}%`}
                contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                labelStyle={{ color: '#fff' }}
                itemStyle={{ color: '#fff' }}
              />
              <Legend wrapperStyle={{ color: '#fff' }} />
              <Line
                type="monotone"
                dataKey="strategy1Percent"
                name="Estratégia 1 (%)"
                stroke="#82ca9d"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="strategy2Percent"
                name="Estratégia 2 (%)"
                stroke="#8884d8"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>

          {/* Gráfico de Resultado Acumulado das Estratégias */}
          <h2 className="mt-5">Resultado Acumulado das Estratégias</h2>
          <ResponsiveContainer width="100%" height={500}>
            <LineChart data={combinedChartData}>
              <CartesianGrid stroke="#444" strokeDasharray="3 3" />
              <XAxis dataKey="time" stroke="#fff" />
              <YAxis stroke="#fff" />
              <Tooltip
                formatter={(value) => `${value}`}
                contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                labelStyle={{ color: '#fff' }}
                itemStyle={{ color: '#fff' }}
              />
              <Legend wrapperStyle={{ color: '#fff' }} />
              <Line
                type="monotone"
                dataKey="combinedCumulative"
                name="Resultado Acumulado"
                stroke="#ff7300"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>

          {/* Nova Seção: Correlação entre as Estratégias */}
          <h2 className="mt-5">Correlação entre as Estratégias</h2>
          {correlation !== null && (
            <div className="mb-4">
              <h4 className="text-light">
                Coeficiente de Correlação de Pearson: <strong>{correlation}</strong>
              </h4>
              <p className="text-light">
                <strong>Interpretação:</strong>{' '}
                {correlation > 0.7
                  ? 'Alta correlação positiva.'
                  : correlation > 0.3
                  ? 'Moderada correlação positiva.'
                  : correlation > -0.3
                  ? 'Fraca ou nenhuma correlação.'
                  : correlation > -0.7
                  ? 'Moderada correlação negativa.'
                  : 'Alta correlação negativa.'}
              </p>
            </div>
          )}

          {/* Nova Seção: Métricas das Estratégias */}
          <h2 className="mt-5">Métricas das Estratégias</h2>
          <Row className="mb-4">
            {/* Métricas da Estratégia 1 */}
            <Col md={6}>
              <Card bg="dark" text="light" className="mb-3">
                <Card.Header>Estratégia 1</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <strong>Total Trades:</strong> {totalTrades1}
                  </Card.Text>
                  <Card.Text>
                    <strong>Winrate:</strong> {winRate1}%
                  </Card.Text>
                  <Card.Text>
                    <strong>Profit Factor:</strong> {profitFactor1}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Métricas da Estratégia 2 */}
            <Col md={6}>
              <Card bg="dark" text="light" className="mb-3">
                <Card.Header>Estratégia 2</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <strong>Total Trades:</strong> {totalTrades2}
                  </Card.Text>
                  <Card.Text>
                    <strong>Winrate:</strong> {winRate2}%
                  </Card.Text>
                  <Card.Text>
                    <strong>Profit Factor:</strong> {profitFactor2}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Métricas do Resultado Acumulado das Estratégias */}
          <Row className="mb-5">
            <Col md={6} className="mx-auto">
              <Card bg="dark" text="light">
                <Card.Header>Resultado Acumulado das Estratégias</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <strong>Total Trades:</strong> {totalTradesCombined}
                  </Card.Text>
                  <Card.Text>
                    <strong>Winrate:</strong> {winRateCombined}%
                  </Card.Text>
                  <Card.Text>
                    <strong>Profit Factor:</strong> {profitFactorCombined}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Correlation;
