// src/components/FileSelector.jsx

import React, { useState, useEffect } from 'react';
import { 
  Form, 
  Button, 
  Alert, 
  Card, 
  Spinner 
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importando Bootstrap Icons
import { useDispatch, useSelector } from 'react-redux';
import { setFileData } from '../redux/dataSlice'; // Verifique se o caminho está correto
import '../index.css';
import * as XLSX from 'xlsx'; // Importando a biblioteca xlsx

const FileSelector = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.data.isDarkMode);
  const posicoes = useSelector((state) => state.data.fileData.posicoes); // Seleciona as posições do Redux

  const [file, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  // Mapeamento de colunas do inglês para o português
  const colunaMapeamento = {
    'Time': 'Horário',
    'Position': 'Position',
    'Symbol': 'Ativo',
    'Type': 'Tipo',
    'Volume': 'Volume',
    'Price': 'Preço',
    'S / L': 'S / L',
    'T / P': 'T / P',
    'Commission': 'Comissão',
    'Swap': 'Swap',
    'Profit': 'Lucro',
    'Horário': 'Horário',
    'Ativo': 'Ativo',
    'Tipo': 'Tipo',
    'Volume': 'Volume',
    'Preço': 'Preço',
    'S / L': 'S / L',
    'T / P': 'T / P',
    'Comissão': 'Comissão',
    'Swap': 'Swap',
    'Lucro': 'Lucro'
  };

  // useEffect para carregar dados do localStorage ao iniciar o componente
  useEffect(() => {
    const storedData = localStorage.getItem('fileData');
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData.posicoes && Array.isArray(parsedData.posicoes)) {
          dispatch(setFileData(parsedData));
          console.log('Dados carregados do localStorage para o Redux.');
        }
      } catch (error) {
        console.error('Erro ao parsear dados do localStorage:', error);
      }
    }
  }, [dispatch]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log('Arquivo selecionado:', selectedFile.name);
      console.log('Tamanho do arquivo:', selectedFile.size, 'bytes');
      setSelectedFile(selectedFile);
      setErrorMessage(null);
      setSuccessMessage(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!file) {
      setErrorMessage('Por favor, selecione um arquivo.');
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(false);

    console.log('Processando arquivo:', file.name);

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      try {
        const workbook = XLSX.read(data, { type: 'binary' });

        // Lista de possíveis nomes para a tabela de posições
        const possibleSheetNames = ['Posições', 'Positions'];
        let sheet = null;

        // Procurar a folha que corresponde a um dos nomes possíveis
        for (const nomeFolha of possibleSheetNames) {
          if (workbook.SheetNames.includes(nomeFolha)) {
            sheet = workbook.Sheets[nomeFolha];
            break;
          }
        }

        // Se nenhuma folha correspondente for encontrada, utilizar a primeira folha
        if (!sheet) {
          console.warn('Nenhuma folha correspondente encontrada. Utilizando a primeira folha.');
          const sheetName = workbook.SheetNames[0];
          sheet = workbook.Sheets[sheetName];
        }

        // Converter a planilha em uma matriz de matrizes
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });

        // Implementar aqui a lógica para extrair a seção "Posições" ou "Positions"
        let posicoesLista = [];
        let dentroDePosicoes = false;
        let cabecalhoPosicoes = [];
        let mapaCabecalhoPosicoes = {};

        jsonData.forEach((linha) => {
          const primeiraCelula = linha[0];

          // Verificar se a linha marca o início da seção de posições
          if (primeiraCelula === 'Posições' || primeiraCelula === 'Positions') {
            dentroDePosicoes = true;
            cabecalhoPosicoes = [];
            mapaCabecalhoPosicoes = {};
            return;
          }

          // Verificar se a linha marca o término da seção de posições
          if (dentroDePosicoes && (primeiraCelula === 'Ordens' || primeiraCelula === 'Orders' || !primeiraCelula)) {
            dentroDePosicoes = false;
            return;
          }

          if (dentroDePosicoes) {
            if (cabecalhoPosicoes.length === 0) {
              // Traduzir e renomear cabeçalhos duplicados
              cabecalhoPosicoes = linha.map((cabecalho) => {
                // Traduzir o cabeçalho se estiver em inglês
                let cabecalhoTraduzido = colunaMapeamento[cabecalho] || cabecalho;

                if (cabecalhoTraduzido === 'Horário') {
                  if (!mapaCabecalhoPosicoes[cabecalhoTraduzido]) {
                    mapaCabecalhoPosicoes[cabecalhoTraduzido] = 1;
                    return 'Horário Entrada';
                  } else {
                    mapaCabecalhoPosicoes[cabecalhoTraduzido]++;
                    return 'Horário Saída';
                  }
                }

                if (cabecalhoTraduzido === 'Preço') {
                  if (!mapaCabecalhoPosicoes[cabecalhoTraduzido]) {
                    mapaCabecalhoPosicoes[cabecalhoTraduzido] = 1;
                    return 'Preço Entrada';
                  } else {
                    mapaCabecalhoPosicoes[cabecalhoTraduzido]++;
                    return 'Preço Saída';
                  }
                }

                return cabecalhoTraduzido;
              });
              return;
            } else {
              let operacao = {};
              cabecalhoPosicoes.forEach((nomeColuna, indice) => {
                operacao[nomeColuna] = linha[indice];
              });

              // Verificar se a linha é válida antes de processar
              if (!operacao['Position'] && !operacao['Posição']) {
                return;
              }

              // Processar os campos necessários
              operacao.volume = parseFloat((operacao['Volume'] || '0').toString().replace(',', '.')) || 0;
              operacao.precoEntrada = parseFloat((operacao['Preço Entrada'] || '0').toString().replace(',', '.')) || 0;
              operacao.precoSaida = parseFloat((operacao['Preço Saída'] || operacao['Preço'] || '0').toString().replace(',', '.')) || 0;
              operacao.comissao = parseFloat((operacao['Comissão'] || '0').toString().replace(',', '.')) || 0;
              operacao.swap = parseFloat((operacao['Swap'] || '0').toString().replace(',', '.')) || 0;
              operacao.lucro = parseFloat((operacao['Lucro'] || '0').toString().replace(',', '.')) || 0;

              operacao.horarioEntrada = operacao['Horário Entrada'] || '';
              operacao.horarioSaida = operacao['Horário Saída'] || '';

              posicoesLista.push(operacao);
            }
          }
        });

        // Função para converter string de data para objeto Date
        const parseDate = (dateString) => {
          // Tente diferentes formatos de data conforme necessário
          const parsedDate = new Date(dateString);
          if (!isNaN(parsedDate)) {
            return parsedDate;
          } else {
            // Tente outro formato se necessário
            // Por exemplo, se a data estiver no formato 'DD/MM/YYYY HH:MM:SS'
            const partes = dateString.split(/[/:\s]/);
            if (partes.length >= 2) {
              const day = parseInt(partes[0], 10);
              const month = parseInt(partes[1], 10) - 1; // Meses são 0-indexados
              const year = parseInt(partes[2], 10);
              const hours = parseInt(partes[3] || 0, 10);
              const minutes = parseInt(partes[4] || 0, 10);
              const seconds = parseInt(partes[5] || 0, 10);
              return new Date(year, month, day, hours, minutes, seconds);
            }
            return null;
          }
        };

        // Filtrar apenas as operações com 'horarioSaida' válido e converter para Date
        posicoesLista = posicoesLista.filter((operacao) => {
          if (operacao.horarioSaida) {
            const data = parseDate(operacao.horarioSaida);
            if (data) {
              operacao.horarioSaidaDate = data;
              return true;
            }
          }
          return false;
        });

        // Ordenar as operações pelo horário de saída
        posicoesLista.sort((a, b) => a.horarioSaidaDate - b.horarioSaidaDate);

        // Remover a propriedade temporária 'horarioSaidaDate'
        posicoesLista.forEach((operacao) => {
          delete operacao.horarioSaidaDate;
        });

        // Salvar os dados no Redux
        dispatch(setFileData({ posicoes: posicoesLista }));

        // Salvar os dados no localStorage para persistência futura
        localStorage.setItem('fileData', JSON.stringify({ posicoes: posicoesLista }));

        setSuccessMessage(true);
      } catch (error) {
        console.error('Erro ao processar o arquivo:', error);
        setErrorMessage('Erro ao processar o arquivo: ' + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    reader.onerror = (event) => {
      console.error('Erro ao ler o arquivo:', event.target.error);
      setErrorMessage('Erro ao ler o arquivo: ' + event.target.error.message);
      setIsLoading(false);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <Card className={isDarkMode ? 'bg-dark text-white' : 'bg-light text-dark'}>
      <Card.Body>
        <Card.Title className="text-center mb-4">Análise de Arquivo XLSX</Card.Title>

        {/* Instrução para o usuário */}
        <Alert variant="info">
          Por favor, faça o upload de uma planilha <strong>.xlsx</strong> que contenha os dados exportados do <strong>Meta Trader</strong>. 
          Para isso, siga os passos abaixo:
          <ol>
            <li>Abra seu Meta Trader e vá até a seção de relatórios.</li>
            <li>Exporte os dados desejados no formato <strong>.xlsx</strong>.</li>
            <li>No formulário abaixo, clique em "Selecionar arquivo" e escolha o arquivo exportado.</li>
            <li>Após selecionar o arquivo, clique em "Analisar Arquivo" para processar os dados.</li>
          </ol>
          Certifique-se de que o arquivo esteja no formato correto para garantir uma análise precisa.
        </Alert>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Selecione um arquivo XLSX</Form.Label>
            <Form.Control 
              type="file" 
              onChange={handleFileChange} 
              accept=".xlsx" // Aceitar apenas arquivos .xlsx
              className={isDarkMode ? 'bg-dark text-white' : ''}
            />
          </Form.Group>
          <Button 
            variant="primary" 
            type="submit" 
            className="w-100"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner 
                  as="span" 
                  animation="border" 
                  size="sm" 
                  role="status" 
                  aria-hidden="true" 
                /> 
                &nbsp;Analisando...
              </>
            ) : (
              'Analisar Arquivo'
            )}
          </Button>
        </Form>

        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert variant="success" className="mt-3">
            Arquivo analisado e dados salvos com sucesso!
          </Alert>
        )}

        {/* Exibir a quantidade de operações catalogadas */}
        {posicoes && posicoes.length > 1 && (
          <p className="mt-3">
            Ao todo, <strong>{posicoes.length}</strong> operações catalogadas com sucesso!
          </p>
        )}
      </Card.Body>
    </Card>
  );
};

export default FileSelector;
