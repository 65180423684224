import React, { useMemo } from 'react';
import { useTable, usePagination, useFilters } from 'react-table';
import { useSelector } from 'react-redux';
import { Table, Button, FormControl, Alert } from 'react-bootstrap';
import './PositionsDetails.css';

// Função para renderizar filtros de coluna
const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}) => {
  return (
    <FormControl
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Remove o filtro se o valor estiver vazio
      }}
      placeholder={`Filtrar...`}
      className="mt-2"
      size="sm"
    />
  );
};

const PositionsDetails = () => {
  // Seleciona as posições do Redux
  const posicoes = useSelector((state) => state.data.fileData.posicoes);
  
  // Seleciona o tema escuro do Redux
  const darkMode = useSelector((state) => state.data.darkMode);

  // Definição das colunas da tabela
  const columns = useMemo(
    () => [
      {
        Header: 'Horário de Entrada',
        accessor: 'horarioEntrada',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Position',
        accessor: 'Position',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Ativo',
        accessor: 'Ativo',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Tipo',
        accessor: 'Tipo',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Volume',
        accessor: 'Volume',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'S / L',
        accessor: 'SL', // Ajuste o accessor conforme a chave correta no estado
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'T / P',
        accessor: 'TP', // Ajuste o accessor conforme a chave correta no estado
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Horário de Saída',
        accessor: 'horarioSaida',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Comissão',
        accessor: 'Comissao', // Ajuste se a chave tiver caracteres especiais
        Cell: ({ value }) => (value !== undefined ? parseFloat(value).toFixed(2) : '-'),
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Swap',
        accessor: 'Swap',
        Cell: ({ value }) => (value !== undefined ? parseFloat(value).toFixed(2) : '-'),
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Lucro',
        accessor: 'Lucro',
        Cell: ({ value }) => (
          <span style={{ color: value > 0 ? 'green' : 'red' }}>
            {value !== undefined ? parseFloat(value).toFixed(2) : '-'}
          </span>
        ),
        Filter: DefaultColumnFilter,
      },
    ],
    []
  );

  // Preparação dos dados para a tabela
  const data = useMemo(() => posicoes || [], [posicoes]);

  // Configuração da tabela com react-table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Usado para paginação
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Define a página inicial e o tamanho da página
    },
    useFilters, // Hook para filtros de coluna
    usePagination // Hook para paginação
  );

  // Define classes condicionais com base no tema escuro
  const tableClass = darkMode ? 'table-dark mt-3 small-text-table' : 'mt-3 small-text-table';

  // Renderização da tabela
  return (
    <>
      {posicoes && posicoes.length > 0 ? (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className={tableClass}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                      {/* Renderiza o filtro de coluna se disponível */}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* Seção de Paginação */}
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Button
                variant="secondary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="me-2"
              >
                Anterior
              </Button>
              <Button
                variant="secondary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Próxima
              </Button>
            </div>
            <span>
              Página{' '}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </span>
            <div>
              <FormControl
                as="select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                size="sm"
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    Mostrar {size}
                  </option>
                ))}
              </FormControl>
            </div>
          </div>
        </>
      ) : (
        <Alert variant="warning" className="mt-3">
          Nenhuma posição disponível.
        </Alert>
      )}
    </>
  );
};

export default PositionsDetails;
