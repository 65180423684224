// src/redux/dataSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileData: {posicoes: [{
    'Horário Entrada': "2024",
    Position: 0,
    Ativo: "",
    Tipo: "",
    Volume: "",
    Preço: 0,
    'S / L': "",
    'T / P': "",
    'Horário Saída': "",
    Comissão: 0,
    Swap: 0,
    Lucro: 0,
    volume: 0,
    precoEntrada: 0,
    precoSaida: 0,
    comissao: 0,
    swap: 0,
    lucro: 0,
    horarioEntrada: "2024",
    horarioSaida: "2024",
  }]},
  isDarkMode: false, // Valor inicial será atualizado no useEffect do componente Navegacao
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setFileData(state, action) {
      state.fileData = action.payload;
    },
    clearFileData(state) {
      state.fileData = null;
    },
    toggleTheme(state) {
      state.isDarkMode = !state.isDarkMode;
      const theme = state.isDarkMode ? 'dark' : 'light';
      localStorage.setItem('fileSelectorTheme', theme);
      document.body.setAttribute('data-bs-theme', theme);
    },
    setTheme(state, action) {
      state.isDarkMode = action.payload;
      const theme = state.isDarkMode ? 'dark' : 'light';
      localStorage.setItem('fileSelectorTheme', theme);
      document.body.setAttribute('data-bs-theme', theme);
    },
  },
});

export const { setFileData, clearFileData, toggleTheme, setTheme } = dataSlice.actions;

export default dataSlice.reducer;
