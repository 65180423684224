// TradingMetrics.jsx
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Table } from 'react-bootstrap';
import './TradingMetrics.css';

const TradingMetrics = () => {
  const posicoes = useSelector((state) => state.data.fileData.posicoes);
  const darkMode = useSelector((state) => state.data.isDarkMode);

  const formatNumber = (num, decimals = 2) => {
    return num !== undefined && num !== null ? num.toFixed(decimals) : '-';
  };

  const getValueClass = (num) => {
    if (num > 0) return 'positive';
    if (num < 0) return 'negative';
    return '';
  };

  const metrics = useMemo(() => {
    if (!posicoes || posicoes.length === 0) {
      return null;
    }

    let totalLucro = 0;
    let lucroBruto = 0;
    let perdaBruta = 0;
    let numeroDeTrades = posicoes.length;
    let numeroDeVitorias = 0;
    let numeroDePerdas = 0;
    let numeroDeCancelados = 0;
    let maiorVitoria = 0;
    let maiorPerda = 0;
    let vitoriasConsecutivas = 0;
    let maxVitoriasConsecutivas = 0;
    let perdasConsecutivas = 0;
    let maxPerdasConsecutivas = 0;
    let retornos = [];
    let tradeDates = {};
    let swapTotal = 0;
    let comissaoTotal = 0;
    let capitalInicial = 10000;
    let equity = capitalInicial;
    let peak = capitalInicial;
    let maxDrawdown = 0;

    let tradeMonthsMap = {};
    let tradeYearsMap = {};

    posicoes.forEach((posicao) => {
      const lucro = parseFloat(posicao.lucro) || 0;
      totalLucro += lucro;

      const volume = parseFloat(posicao.volume) || 0.01;
      const precoEntrada = parseFloat(posicao.precoEntrada) || 0;
      const precoSaida = parseFloat(posicao.precoSaida) || 0;
      const swap = parseFloat(posicao.Swap) || 0;
      const comissao = parseFloat(posicao.Comissão) || 0;

      swapTotal += swap;
      comissaoTotal += comissao;

      if (lucro > 0) {
        lucroBruto += lucro;
        numeroDeVitorias += 1;
        maiorVitoria = Math.max(maiorVitoria, lucro);
        vitoriasConsecutivas += 1;
        maxVitoriasConsecutivas = Math.max(maxVitoriasConsecutivas, vitoriasConsecutivas);
        perdasConsecutivas = 0;
      } else if (lucro < 0) {
        perdaBruta += Math.abs(lucro);
        numeroDePerdas += 1;
        maiorPerda = Math.min(maiorPerda, lucro);
        perdasConsecutivas += 1;
        maxPerdasConsecutivas = Math.max(maxPerdasConsecutivas, perdasConsecutivas);
        vitoriasConsecutivas = 0;
      } else {
        numeroDeCancelados += 1;
        vitoriasConsecutivas = 0;
        perdasConsecutivas = 0;
      }

      retornos.push(lucro);

      const dataSaida = new Date(posicao.horarioSaida);
      const dateKey = dataSaida.toISOString().split('T')[0];
      if (!tradeDates[dateKey]) {
        tradeDates[dateKey] = { profit: 0 };
      }
      tradeDates[dateKey].profit += lucro;

      const monthKey = `${dataSaida.getFullYear()}-${String(dataSaida.getMonth() + 1).padStart(2, '0')}`;
      if (!tradeMonthsMap[monthKey]) {
        tradeMonthsMap[monthKey] = { profit: 0 };
      }
      tradeMonthsMap[monthKey].profit += lucro;

      const yearKey = `${dataSaida.getFullYear()}`;
      if (!tradeYearsMap[yearKey]) {
        tradeYearsMap[yearKey] = { profit: 0 };
      }
      tradeYearsMap[yearKey].profit += lucro;

      equity += lucro;
      equity += swap; 
      equity += comissao;
      if (equity > peak) {
        peak = equity;
      }
      const drawdown = peak - equity;
      if (drawdown > maxDrawdown) {
        maxDrawdown = drawdown;
      }
    });

    const monthlyPerformance = Object.keys(tradeMonthsMap)
      .sort()
      .map((month) => ({
        month,
        profit: tradeMonthsMap[month].profit,
      }));

    const annualPerformance = Object.keys(tradeYearsMap)
      .sort()
      .map((year) => ({
        year,
        profit: tradeYearsMap[year].profit,
      }));

    const fatorDeLucro = perdaBruta !== 0 ? lucroBruto / perdaBruta : 0;
    const porcentagemDeVitorias = numeroDeTrades > 0 ? (numeroDeVitorias / numeroDeTrades) * 100 : 0;
    const porcentagemDeDrawdown = capitalInicial > 0 ? (maxDrawdown / capitalInicial) * 100 : 0;
    const lucroMedioPorTrade = numeroDeTrades > 0 ? totalLucro / numeroDeTrades : 0;

    const firstTradeDate = new Date(posicoes[0].horarioSaida);
    const lastTradeDate = new Date(posicoes[posicoes.length - 1].horarioSaida);
    const anos = (lastTradeDate - firstTradeDate) / (1000 * 60 * 60 * 24 * 365);
    const lucroMedioAnual = anos > 0 ? totalLucro / anos : totalLucro;
    const retornoMedioAnual = capitalInicial > 0 ? (lucroMedioAnual / capitalInicial) * 100 : 0;
    const CAGR = anos > 0 ? ((equity / capitalInicial) ** (1 / anos) - 1) * 100 : 0;

    const mediaRetorno = retornos.reduce((a, b) => a + b, 0) / retornos.length;
    const variancia = retornos.reduce((a, b) => a + Math.pow(b - mediaRetorno, 2), 0) / retornos.length;
    const desvioPadrao = Math.sqrt(variancia);
    const sharpeRatio = desvioPadrao !== 0 ? mediaRetorno / desvioPadrao : 0;

    const ratioRetornoDrawdown = maxDrawdown !== 0 ? totalLucro / maxDrawdown : 0;
    const avgWin = numeroDeVitorias > 0 ? lucroBruto / numeroDeVitorias : 0;
    const avgLoss = numeroDePerdas > 0 ? perdaBruta / numeroDePerdas : 0;
    const expectativa = (avgWin * (numeroDeVitorias / numeroDeTrades)) - (avgLoss * (numeroDePerdas / numeroDeTrades));
    const payoutRatio = avgLoss !== 0 ? avgWin / avgLoss : 0;
    const desvio = desvioPadrao;
    const totalDiasComTrades = Object.keys(tradeDates).length;
    const lucroMedioDiario = totalDiasComTrades > 0 ? totalLucro / totalDiasComTrades : 0;

    const tradeMonths = {};
    posicoes.forEach((posicao) => {
      const dataSaida = new Date(posicao.horarioSaida);
      const monthKey = `${dataSaida.getFullYear()}-${dataSaida.getMonth() + 1}`;
      if (!tradeMonths[monthKey]) {
        tradeMonths[monthKey] = { profit: 0 };
      }
      tradeMonths[monthKey].profit += parseFloat(posicao.lucro) || 0;
    });
    const totalMesesComTrades = Object.keys(tradeMonths).length;
    const lucroMedioMensal = totalMesesComTrades > 0 ? totalLucro / totalMesesComTrades : 0;

    const ratioVitoriasPerdas = numeroDePerdas !== 0 ? numeroDeVitorias / numeroDePerdas : numeroDeVitorias;
    const expectativaDeLucro = expectativa;
    const razaoPagamento = payoutRatio;
    const zScore = 0;
    const probabilidadeZ = 0;
    const diasEstagnacao = 0;
    const porcentagemEstagnacao = 0;
    const barrasMediasPorTrade = 0;
    const exposicaoMaximaPosicao = 0;
    const exposicaoMaximaLotes = 0;
    const rExpectancyScore = expectativa;
    const numeroQualidadeSTR = 0;
    const scoreSQN = 0;

    const perdaMedia = avgLoss;

    return {
      totalLucro,
      lucroMedioAnual,
      retornoMedioAnual,
      CAGR,
      numeroDeTrades,
      sharpeRatio,
      fatorDeLucro,
      ratioRetornoDrawdown,
      porcentagemDeVitorias,
      maxDrawdown,
      porcentagemDeDrawdown,
      lucroMedioDiario,
      lucroMedioMensal,
      lucroMedioPorTrade,
      ratioVitoriasPerdas,
      expectativaDeLucro,
      razaoPagamento,
      desvio,
      expectativa,
      payoutRatio,
      zScore,
      probabilidadeZ,
      diasEstagnacao,
      porcentagemEstagnacao,
      barrasMediasPorTrade,
      exposicaoMaximaPosicao,
      exposicaoMaximaLotes,
      rExpectancyScore,
      numeroQualidadeSTR,
      scoreSQN,
      lucroBruto,
      numeroDeVitorias,
      maiorVitoria,
      maxVitoriasConsecutivas,
      numeroDePerdas,
      perdaBruta,
      maiorPerda,
      maxPerdasConsecutivas,
      numeroDeCancelados,
      perdaMedia,
      maxVitoriasConsecutivas,
      maxPerdasConsecutivas,
      swapTotal,
      comissaoTotal,
      monthlyPerformance,
      annualPerformance,
    };
  }, [posicoes]);

  if (!metrics) {
    return (
      <Card className={darkMode ? 'bg-dark text-white' : ''}>
        <Card.Body>
          <Card.Title>Métricas de Trading</Card.Title>
          <Card.Text>Nenhuma posição disponível para calcular as métricas.</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  const renderMetricPair = (metric1, label1, metric2, label2) => (
    <Row className="mb-2">
      <Col>
        <strong>{label1}:</strong>{' '}
        <span className={'span-value'}>
          {formatNumber(metric1)}
          {typeof metric1 === 'number' &&
            (metric1 > 0 || metric1 < 0) &&
            (metrics[label1.replace(/\s+/g, '')] > 0 ? '%' : '')}
        </span>
      </Col>
      <Col>
        <strong>{label2}:</strong>{' '}
        <span className={'span-value'}>
          {formatNumber(metric2)}
          {typeof metric2 === 'number' &&
            (metric2 > 0 || metric2 < 0) &&
            (metrics[label2.replace(/\s+/g, '')] > 0 ? '%' : '')}
        </span>
      </Col>
    </Row>
  );

  return (
    <div className={`metrics-sidebar-container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Card className={darkMode ? 'bg-dark text-white mb-3' : 'mb-3'}>
        <Card.Header>Lucro Total</Card.Header>
        <Card.Body>
          {renderMetricPair(metrics.totalLucro, 'Lucro Total', metrics.CAGR, 'CAGR')}
          {renderMetricPair(metrics.lucroMedioAnual, 'Lucro Médio Anual', metrics.retornoMedioAnual, 'Retorno Médio Anual %')}
        </Card.Body>
      </Card>

      <Card className={darkMode ? 'bg-dark text-white mb-3' : 'mb-3'}>
        <Card.Header>Métricas de Risco e Retorno</Card.Header>
        <Card.Body>
          {renderMetricPair(metrics.numeroDeTrades, 'Número de Trades', metrics.sharpeRatio, 'Sharpe Ratio')}
          {renderMetricPair(metrics.fatorDeLucro, 'Fator de Lucro', metrics.ratioRetornoDrawdown, 'Razão Retorno / Drawdown')}
          {renderMetricPair(metrics.porcentagemDeVitorias, 'Porcentagem de Vitorias', metrics.maxDrawdown, 'Drawdown Máximo')}
          {renderMetricPair(metrics.porcentagemDeDrawdown, '% Drawdown', metrics.lucroMedioDiario, 'Lucro Médio Diário')}
          {renderMetricPair(metrics.lucroMedioMensal, 'Lucro Médio Mensal', metrics.lucroMedioPorTrade, 'Lucro Médio por Trade')}
          {renderMetricPair(metrics.retornoMedioAnual, 'Retorno % Anual', metrics.maxDrawdown, 'Max Drawdown %')}
          {renderMetricPair(metrics.rExpectancyScore, 'Pontuação Expectativa R', metrics.numeroQualidadeSTR, 'Número de Qualidade STR')}
          {renderMetricPair(metrics.scoreSQN, 'Pontuação SQN', metrics.swapTotal, 'Swap Total')}
          {renderMetricPair(metrics.comissaoTotal, 'Comissão Total', null, null)}
        </Card.Body>
      </Card>

      <Card className={darkMode ? 'bg-dark text-white mb-3' : 'mb-3'}>
        <Card.Header>Estatísticas</Card.Header>
        <Card.Body>
          {renderMetricPair(metrics.ratioVitoriasPerdas, 'Razão Vitorias / Perdas', metrics.expectativaDeLucro, 'Expectativa de Lucro')}
          {renderMetricPair(metrics.razaoPagamento, 'Razão de Pagamento (Lucro Médio / Perda Média)', metrics.zScore, 'Z-Score')}
          {renderMetricPair(metrics.desvio, 'Desvio Padrão', metrics.probabilidadeZ, 'Probabilidade Z')}
          {renderMetricPair(metrics.porcentagemEstagnacao, 'Estagnação em %', metrics.barrasMediasPorTrade, 'Média de Barras por Trade')}
          {renderMetricPair(metrics.exposicaoMaximaPosicao, 'Exposição Máxima de Posição', metrics.exposicaoMaximaLotes, 'Exposição Máxima de Lotes')}
        </Card.Body>
      </Card>

      <Card className={darkMode ? 'bg-dark text-white mb-3' : 'mb-3'}>
        <Card.Header>Trades</Card.Header>
        <Card.Body>
          {renderMetricPair(metrics.lucroBruto, 'Lucro Bruto', metrics.numeroDeVitorias, 'Número de Vitorias')}
          {renderMetricPair(metrics.maiorVitoria, 'Maior Vitoria', metrics.maxVitoriasConsecutivas, 'Máximo de Vitorias Consecutivas')}
          {renderMetricPair(metrics.numeroDePerdas, 'Número de Perdas', metrics.perdaBruta, 'Perda Bruta')}
          {renderMetricPair(metrics.maiorPerda, 'Maior Perda', metrics.maxPerdasConsecutivas, 'Máximo de Perdas Consecutivas')}
          {renderMetricPair(metrics.numeroDeCancelados, 'Número de Trades Cancelados/Expirados', metrics.perdaMedia, 'Perda Média')}
        </Card.Body>
      </Card>

      <Card className={darkMode ? 'bg-dark text-white mb-3' : 'mb-3'}>
        <Card.Header>Desempenho Mensal</Card.Header>
        <Card.Body>
          <Table striped bordered hover variant={darkMode ? 'dark' : 'light'}>
            <thead>
              <tr>
                <th>Mês</th>
                <th>Lucro</th>
              </tr>
            </thead>
            <tbody>
              {metrics.monthlyPerformance.map((monthData) => (
                <tr key={monthData.month}>
                  <td>{monthData.month}</td>
                  <td className={getValueClass(monthData.profit)}>
                    {formatNumber(monthData.profit)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className={darkMode ? 'bg-dark text-white mb-3' : 'mb-3'}>
        <Card.Header>Desempenho Anual</Card.Header>
        <Card.Body>
          <Table striped bordered hover variant={darkMode ? 'dark' : 'light'}>
            <thead>
              <tr>
                <th>Ano</th>
                <th>Lucro</th>
              </tr>
            </thead>
            <tbody>
              {metrics.annualPerformance.map((yearData) => (
                <tr key={yearData.year}>
                  <td>{yearData.year}</td>
                  <td className={getValueClass(yearData.profit)}>
                    {formatNumber(yearData.profit)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TradingMetrics;
