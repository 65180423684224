import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Alert, Button, Table, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './TradesCalendar.css';

const TradesCalendar = () => {
  const posicoes = useSelector((state) => state.data.fileData.posicoes);
  const darkMode = useSelector((state) => state.data.isDarkMode);

  const [selectedDate, setSelectedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tradesOnSelectedDate, setTradesOnSelectedDate] = useState([]);

  const tradeDates = useMemo(() => {
    if (!posicoes) return [];

    const dateMap = posicoes.reduce((acc, posicao) => {
      const date = new Date(posicao.horarioSaida).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = { date: new Date(posicao.horarioSaida), lucro: 0 };
      }
      acc[date].lucro += parseFloat(posicao.Lucro) || 0;
      return acc;
    }, {});

    return Object.values(dateMap);
  }, [posicoes]);

  const getTileClass = ({ date, view }) => {
    if (view !== 'month') return null;

    const tradesForDate = tradeDates.filter(trade =>
      trade.date.getFullYear() === date.getFullYear() &&
      trade.date.getMonth() === date.getMonth() &&
      trade.date.getDate() === date.getDate()
    );

    if (tradesForDate.length === 0) return null;

    const totalLucro = tradesForDate.reduce((sum, trade) => sum + trade.lucro, 0);

    return totalLucro >= 0 ? 'trade-day-positive' : 'trade-day-negative';
  };

  const getTileContent = ({ date, view }) => {
    if (view !== 'month') return null;

    const tradesForDate = tradeDates.filter(trade =>
      trade.date.getFullYear() === date.getFullYear() &&
      trade.date.getMonth() === date.getMonth() &&
      trade.date.getDate() === date.getDate()
    );

    if (tradesForDate.length === 0) return null;

    const totalLucro = tradesForDate.reduce((sum, trade) => sum + trade.lucro, 0);

    return (
      <div className={`tile-content ${darkMode ? 'dark' : 'light'}`}>
        <span className={totalLucro >= 0 ? 'lucro' : 'prejuizo'}>
          {totalLucro >= 0 ? `+${totalLucro.toFixed(2)}` : `${totalLucro.toFixed(2)}`}
        </span>
      </div>
    );
  };

  const legendItems = [
    { color: '#4caf50', label: 'Dias com Trades Positivos' },
    { color: '#f44336', label: 'Dias com Trades Negativos' }
  ];

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const filteredTrades = posicoes.filter(posicao => {
      const posicaoDate = new Date(posicao.horarioSaida);
      return (
        posicaoDate.getFullYear() === date.getFullYear() &&
        posicaoDate.getMonth() === date.getMonth() &&
        posicaoDate.getDate() === date.getDate()
      );
    });
    setTradesOnSelectedDate(filteredTrades);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDate(null);
    setTradesOnSelectedDate([]);
  };

  return (
    <div className={`trades-calendar-container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Row>
        <Col md={12} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Visualização de Trades por Data</Card.Title>
              <Calendar
                onClickDay={handleDateClick}
                tileClassName={getTileClass}
                tileContent={getTileContent}
                className={darkMode ? 'react-calendar-dark' : ''}
              />
              <div className="legend mt-2">
                {legendItems.map((item, index) => (
                  <span key={index} className="legend-item" style={{ color: item.color }}>
                    {item.label}
                  </span>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal 
        show={showModal} 
        onHide={handleCloseModal} 
        size="xl"
        centered
        className={darkMode ? 'modal-dark' : ''}
      >
        <Modal.Header closeButton className={darkMode ? 'modal-header-dark' : ''}>
          <Modal.Title>Trades em {selectedDate ? selectedDate.toLocaleDateString() : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={darkMode ? 'modal-body-dark' : ''}>
          {tradesOnSelectedDate.length > 0 ? (
            <Table 
              striped 
              bordered 
              hover 
              responsive 
              className={`mt-3 ${darkMode ? 'table-dark' : ''}`}
            >
              <thead>
                <tr>
                  <th>Horário de Entrada</th>
                  <th>Position</th>
                  <th>Ativo</th>
                  <th>Tipo</th>
                  <th>Volume</th>
                  <th>S / L</th>
                  <th>T / P</th>
                  <th>Horário de Saída</th>
                  <th>Comissão</th>
                  <th>Swap</th>
                  <th>Lucro</th>
                </tr>
              </thead>
              <tbody>
                {tradesOnSelectedDate.map((posicao, index) => (
                  <tr key={index}>
                    <td>{posicao.horarioEntrada || '-'}</td>
                    <td>{posicao.Position || '-'}</td>
                    <td>{posicao.Ativo || '-'}</td>
                    <td>{posicao.Tipo || '-'}</td>
                    <td>{posicao.Volume}</td>
                    <td>{posicao['S / L'] || '-'}</td>
                    <td>{posicao['T / P'] || '-'}</td>
                    <td>{posicao.horarioSaida || '-'}</td>
                    <td>{posicao.Comissão !== undefined ? parseFloat(posicao.Comissão).toFixed(2) : '-'}</td>
                    <td>{posicao.Swap !== undefined ? parseFloat(posicao.Swap).toFixed(2) : '-'}</td>
                    <td className={posicao.Lucro > 0 ? 'lucro-text' : 'prejuizo-text'}>
                      {posicao.Lucro !== undefined ? parseFloat(posicao.Lucro).toFixed(2) : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="warning">Nenhum trade encontrado para esta data.</Alert>
          )}
        </Modal.Body>
        <Modal.Footer className={darkMode ? 'modal-footer-dark' : ''}>
          <Button variant={darkMode ? 'secondary' : 'primary'} onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TradesCalendar;
