import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Alert, Modal, Button, Table, Form } from 'react-bootstrap';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  PieChart, Pie, Cell,
  ResponsiveContainer,
  LineChart, Line
} from 'recharts';
import './StatisticsView.css';

const CACHE_KEY_POSICOES = 'statisticsView_posicoes'; // Chave para o localStorage

const StatisticsView = ({ labelMapping, COLORS }) => {
  // Seleciona o estado inteiro para depuração
  const entireState = useSelector(state => state);

  // Seleciona posicoes com tratamento de erro
  const posicoesFromRedux = useSelector(state => {
    const pos = state.data?.fileData?.posicoes;
    console.log('Posições selecionadas do Redux:', pos);
    return pos;
  });

  // Estado local para posicoes
  const [posicoes, setPosicoes] = useState(() => {
    // Função de inicialização para carregar do cache
    try {
      const cachedData = localStorage.getItem(CACHE_KEY_POSICOES);
      return cachedData ? JSON.parse(cachedData) : posicoesFromRedux;
    } catch (error) {
      console.error('Erro ao carregar posicoes do cache:', error);
      return posicoesFromRedux;
    }
  });

  // Estado para controle do Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  // Estado para o período da média móvel
  const [movingAveragePeriod, setMovingAveragePeriod] = useState(10); // Valor padrão de 10

  // Atualiza o estado local e o cache sempre que posicoesFromRedux mudar
  useEffect(() => {
    try {
      const cachedData = localStorage.getItem(CACHE_KEY_POSICOES);
      const parsedCache = cachedData ? JSON.parse(cachedData) : null;
      if (!parsedCache || JSON.stringify(parsedCache) !== JSON.stringify(posicoesFromRedux)) {
        setPosicoes(posicoesFromRedux);
        localStorage.setItem(CACHE_KEY_POSICOES, JSON.stringify(posicoesFromRedux));
      }
    } catch (error) {
      console.error('Erro ao sincronizar posicoes com o cache:', error);
      setPosicoes(posicoesFromRedux);
    }
  }, [posicoesFromRedux]);

  // Salva posicoes no cache sempre que elas mudarem
  useEffect(() => {
    if (posicoes && Array.isArray(posicoes)) {
      try {
        localStorage.setItem(CACHE_KEY_POSICOES, JSON.stringify(posicoes));
      } catch (error) {
        console.error('Erro ao salvar posicoes no cache:', error);
      }
    }
  }, [posicoes]);

  // Log das mudanças para depuração
  useEffect(() => {
    console.log('Estado completo:', entireState);
    console.log('Posições do Redux:', posicoesFromRedux);
    console.log('Posições locais:', posicoes);
  }, [entireState, posicoesFromRedux, posicoes]);

  // Define esquemas de cores para temas claro e escuro
  const lightTheme = {
    grid: "#e0e0e0",
    axis: "#000000",
    tooltipBg: "#ffffff",
    tooltipColor: "#000000",
    legendColor: "#808080",
  };

  const darkTheme = {
    grid: "#444444",
    axis: "#ffffff",
    tooltipBg: "#333333",
    tooltipColor: "#fff",
    legendColor: "#fff",
  };

  const isDarkMode = useSelector(state => state.data?.isDarkMode);

  const theme = useMemo(() => isDarkMode ? darkTheme : lightTheme, [isDarkMode]);

  // Calcula métricas a partir das posicoes
  const metrics = useMemo(() => {
    if (!posicoes || !Array.isArray(posicoes) || posicoes.length === 0) {
      console.error('Posições is not an array or is empty:', posicoes);
      return {
        lucroBruto: 0,
        perdaBruta: 0,
        lucroLiquidoTotal: 0,
        posicoesVendidas: { total: 0, lucros: 0 },
        posicoesCompradas: { total: 0, lucros: 0 },
        negocLucro: 0,
        negocPerda: 0,
        capitalCurve: []
      };
    }

    let lucroBruto = 0;
    let perdaBruta = 0;
    let lucroLiquidoTotal = 0;
    let posicoesVendidasTotal = 0;
    let posicoesVendidasLucros = 0;
    let posicoesCompradasTotal = 0;
    let posicoesCompradasLucros = 0;
    let negocLucro = 0;
    let negocPerda = 0;
    let capitalCurve = [];
    let capitalAcumulado = 0;

    posicoes.forEach((posicao, index) => {
      const lucro = parseFloat(posicao["Lucro"]) || 0;

      // Calcular Lucro Bruto e Perda Bruta
      if (lucro > 0) {
        lucroBruto += lucro;
      } else {
        perdaBruta += Math.abs(lucro);
      }

      // Calcular Lucro Líquido Total
      lucroLiquidoTotal += lucro;

      // Calcular Posições Vendidas e Compradas
      const tipo = posicao["Tipo"]?.toLowerCase();
      if (tipo === 'sell') {
        posicoesVendidasTotal += 1;
        if (lucro > 0) posicoesVendidasLucros += 1;
      } else if (tipo === 'buy') {
        posicoesCompradasTotal += 1;
        if (lucro > 0) posicoesCompradasLucros += 1;
      }

      // Calcular Negociações com Lucro e Perda
      if (lucro > 0) {
        negocLucro += 1;
      } else if (lucro < 0) {
        negocPerda += 1;
      }

      // Calcular Curva de Capital
      capitalAcumulado += lucro;
      capitalCurve.push({
        trade: index + 1,
        capital: parseFloat(capitalAcumulado.toFixed(2))
      });
    });

    const totalPosicoes = posicoes.length;
    const totalNegociacoes = posicoes.length;

    return {
      lucroBruto: parseFloat(lucroBruto.toFixed(2)),
      perdaBruta: parseFloat(perdaBruta.toFixed(2)),
      lucroLiquidoTotal: parseFloat(lucroLiquidoTotal.toFixed(2)),
      resultadoPosicoesVendidasPercenteganhos: posicoesVendidasTotal > 0
        ? `${((posicoesVendidasLucros / posicoesVendidasTotal) * 100).toFixed(2)}%`
        : "0%",
      resultadoPosicoesCompradasPercentedeganhos: posicoesCompradasTotal > 0
        ? `${((posicoesCompradasLucros / posicoesCompradasTotal) * 100).toFixed(2)}%`
        : "0%",
      fatorDeLucro: perdaBruta > 0 ? parseFloat((lucroBruto / perdaBruta).toFixed(2)) : 0,
      fatorDeRecuperacao: perdaBruta > 0 ? parseFloat((lucroLiquidoTotal / perdaBruta).toFixed(2)) : 0,
      resultadoNegociacoescomLucroPercentOfTotal: totalNegociacoes > 0
        ? `${((negocLucro / totalNegociacoes) * 100).toFixed(2)}%`
        : "0%",
      resultadoNegociacoescomPerdaPercentOfTotal: totalNegociacoes > 0
        ? `${((negocPerda / totalNegociacoes) * 100).toFixed(2)}%`
        : "0%",
      capitalCurve
    };
  }, [posicoes]);

  // Cálculo da Média Móvel integrada na capitalCurve
  const enrichedCapitalCurve = useMemo(() => {
    const period = movingAveragePeriod;
    const capitalCurve = metrics.capitalCurve;
    const enrichedCurve = capitalCurve.map((dataPoint, index) => ({
      ...dataPoint,
      capitalMA: null // Inicializa como null
    }));

    if (period <= 0 || period > capitalCurve.length) {
      // Período inválido ou maior que o número de trades
      return enrichedCurve;
    }

    for (let i = period - 1; i < capitalCurve.length; i++) {
      const window = capitalCurve.slice(i + 1 - period, i + 1);
      const sum = window.reduce((acc, curr) => acc + curr.capital, 0);
      const average = sum / period;
      enrichedCurve[i].capitalMA = parseFloat(average.toFixed(2));
    }

    return enrichedCurve;
  }, [metrics.capitalCurve, movingAveragePeriod]);

  // Cálculo das métricas por ativo para exibição dos cards
  const ativosMetrics = useMemo(() => {
    const ativosMap = {};

    posicoes.forEach(posicao => {
      const ativo = posicao.Ativo || 'Desconhecido';
      const lucro = parseFloat(posicao.Lucro) || 0;

      if (!ativosMap[ativo]) {
        ativosMap[ativo] = { totalLucro: 0, operacoes: [] };
      }

      ativosMap[ativo].totalLucro += lucro;
      ativosMap[ativo].operacoes.push(posicao);
    });

    // Transformar o mapa em array
    return Object.entries(ativosMap).map(([ativo, data]) => ({
      ativo,
      totalLucro: parseFloat(data.totalLucro.toFixed(2)),
      operacoes: data.operacoes
    }));
  }, [posicoes]);

  // Filtra as operações para o ativo selecionado
  const tradesOnSelectedAsset = useMemo(() => {
    if (!selectedAsset) return [];
    return posicoes.filter(posicao => posicao.Ativo === selectedAsset);
  }, [selectedAsset, posicoes]);

  // Funções para abrir e fechar o Modal
  const handleOpenModal = (ativo) => {
    setSelectedAsset(ativo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAsset(null);
  };

  // Função para lidar com a mudança no input do período da média móvel
  const handleMovingAverageChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setMovingAveragePeriod(value);
    } else {
      setMovingAveragePeriod(1); // Valor mínimo para evitar erros
    }
  };

  // Log das métricas calculadas
  useEffect(() => {
    console.log('Métricas calculadas:', metrics);
  }, [metrics]);

  // Adiciona uma verificação para dados vazios ou inválidos
  const hasPosicoes = posicoes && Array.isArray(posicoes) && posicoes.length > 0;

  return (
    <>
      <Row>
        {/* Gráfico: Curva de Capital */}
        <Col md={12} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Curva de Capital</Card.Title>
              
              {/* Input para definir o período da média móvel */}
              <Form.Group controlId="movingAveragePeriod" className="mb-3" style={{ maxWidth: '400px' }}>
                <Form.Label>Período da Média Móvel:</Form.Label>
                <Form.Control 
                  type="number" 
                  min="1" 
                  placeholder="Defina o período" 
                  value={movingAveragePeriod} 
                  onChange={handleMovingAverageChange} 
                />
                <Form.Text className="text-muted">
                  Insira o número de trades para calcular a média móvel.
                </Form.Text>
              </Form.Group>

              <ResponsiveContainer width="100%" height={500}>
                {hasPosicoes ? (
                  enrichedCapitalCurve && enrichedCapitalCurve.length > 0 ? (
                    <LineChart
                      data={enrichedCapitalCurve}
                      margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                      }}
                    >
                      <CartesianGrid stroke={theme.grid} strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="trade" 
                        stroke={theme.axis} 
                        label={{ value: 'Trade', position: 'insideBottomRight', offset: 0, fill: theme.axis }} 
                      />
                      <YAxis 
                        stroke={theme.axis} 
                        label={{ value: 'Capital Acumulado', angle: -90, position: 'insideLeft', fill: theme.axis }} 
                      />
                      <Tooltip 
                        contentStyle={{ backgroundColor: theme.tooltipBg, color: theme.tooltipColor }} 
                        itemStyle={{ color: theme.tooltipColor }} 
                      />
                      <Legend wrapperStyle={{ color: theme.legendColor }} />
                      
                      {/* Linha da Curva de Capital */}
                      <Line 
                        type="monotone" 
                        dataKey="capital" 
                        stroke={isDarkMode ? '#00bfff' : '#007bff'} 
                        name="Capital Acumulado" 
                        dot={false}
                      />

                      {/* Linha da Média Móvel, exibida somente se o período for válido */}
                      {movingAveragePeriod <= metrics.capitalCurve.length && (
                        <Line 
                          type="monotone" 
                          dataKey="capitalMA" 
                          stroke={isDarkMode ? '#ff6347' : '#ff0000'} 
                          name={`Média Móvel (${movingAveragePeriod})`} 
                          dot={false}
                          connectNulls
                        />
                      )}
                    </LineChart>
                  ) : (
                    <Alert variant="warning">Dados para a Curva de Capital não disponíveis.</Alert>
                  )
                ) : (
                  <Alert variant="warning">
                    Não há dados de posições disponíveis ou os dados estão em um formato inválido.
                  </Alert>
                )}
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>

        {/* Gráfico de Lucro Bruto, Perda Bruta e Lucro Líquido Total */}
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Lucro Bruto, Perda Bruta e Lucro Líquido Total</Card.Title>
              <ResponsiveContainer width="100%" height={300}>
                {hasPosicoes ? (
                  <BarChart
                    data={getProfitLossData(metrics)}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid stroke={theme.grid} strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke={theme.axis} />
                    <YAxis stroke={theme.axis} />
                    <Tooltip 
                      contentStyle={{ backgroundColor: theme.tooltipBg, color: theme.tooltipColor }} 
                      itemStyle={{ color: theme.tooltipColor }} 
                    />
                    <Legend wrapperStyle={{ color: theme.legendColor }} />
                    <Bar dataKey="value" name="Valor">
                      {getProfitLossData(metrics).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} />
                      ))}
                    </Bar>
                  </BarChart>
                ) : (
                  <Alert variant="warning">
                    Não há dados de posições disponíveis ou os dados estão em um formato inválido.
                  </Alert>
                )}
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>

        {/* Gráfico de Negociações com Lucro vs Perda */}
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Negociações com Lucro vs Negociações com Perda</Card.Title>
              <ResponsiveContainer width="100%" height={300}>
                {hasPosicoes ? (
                  (metrics.resultadoNegociacoescomLucroPercentOfTotal !== "0%" || metrics.resultadoNegociacoescomPerdaPercentOfTotal !== "0%") ? (
                    <PieChart>
                      <Pie
                        data={getTransactionsPieData(metrics)}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label
                      >
                        {getTransactionsPieData(metrics).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <Tooltip 
                        contentStyle={{ backgroundColor: theme.tooltipBg, color: theme.tooltipColor }} 
                        itemStyle={{ color: theme.tooltipColor }} 
                      />
                      <Legend wrapperStyle={{ color: theme.legendColor }} />
                    </PieChart>
                  ) : (
                    <Alert variant="warning">Dados de Negociações com Lucro ou Perda não disponíveis.</Alert>
                  )
                ) : (
                  <Alert variant="warning">
                    Não há dados de posições disponíveis ou os dados estão em um formato inválido.
                  </Alert>
                )}
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Nova Seção: Ativos com Operações */}
      {hasPosicoes && (
        <Row className="mt-5">
          <Col md={12}>
            <Card>
              <Card.Body>
                <Card.Title>Ativos com Operações</Card.Title>
                <Row>
                  {ativosMetrics.map((ativoData, index) => (
                    <Col key={index} md={3} sm={6} xs={12} className="mb-4">
                      <Card 
                        onClick={() => handleOpenModal(ativoData.ativo)} 
                        style={{ 
                          cursor: 'pointer', 
                          backgroundColor: isDarkMode
                            ? (ativoData.totalLucro >= 0 ? '#4daf51' : '#f44336')
                            : (ativoData.totalLucro >= 0 ? '#d4edda' : '#f8d7da'), 
                          borderColor: ativoData.totalLucro >= 0 ? '#c3e6cb' : '#f5c6cb' 
                        }}
                        className="h-100"
                      >
                        <Card.Body>
                          <Card.Title>{ativoData.ativo}</Card.Title>
                          <Card.Text>
                            Resultado Total: {ativoData.totalLucro >= 0 ? 
                              <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>+{ativoData.totalLucro}</span> : 
                              <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>{ativoData.totalLucro}</span>}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Modal para Exibir Operações do Ativo Selecionado */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton className={isDarkMode ? 'modal-header-dark' : ''}>
          <Modal.Title>Trades em {selectedAsset}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={isDarkMode ? 'modal-body-dark' : ''}>
          {tradesOnSelectedAsset.length > 0 ? (
            <Table 
              striped 
              bordered 
              hover 
              responsive 
              className={`mt-3 ${isDarkMode ? 'table-dark' : ''}`}
            >
              <thead>
                <tr>
                  <th>Horário de Entrada</th>
                  <th>Position</th>
                  <th>Ativo</th>
                  <th>Tipo</th>
                  <th>Volume</th>
                  <th>S / L</th>
                  <th>T / P</th>
                  <th>Horário de Saída</th>
                  <th>Comissão</th>
                  <th>Swap</th>
                  <th>Lucro</th>
                </tr>
              </thead>
              <tbody>
                {tradesOnSelectedAsset.map((posicao, index) => (
                  <tr key={index}>
                    <td>{posicao.horarioEntrada || '-'}</td>
                    <td>{posicao.Position || '-'}</td>
                    <td>{posicao.Ativo || '-'}</td>
                    <td>{posicao.Tipo || '-'}</td>
                    <td>{posicao.Volume}</td>
                    <td>{posicao['S / L'] || '-'}</td>
                    <td>{posicao['T / P'] || '-'}</td>
                    <td>{posicao.horarioSaida || '-'}</td>
                    <td>{posicao.Comissão !== undefined ? parseFloat(posicao.Comissão).toFixed(2) : '-'}</td>
                    <td>{posicao.Swap !== undefined ? parseFloat(posicao.Swap).toFixed(2) : '-'}</td>
                    <td className={posicao.Lucro > 0 ? 'lucro-text' : 'prejuizo-text'}>
                      {posicao.Lucro !== undefined ? parseFloat(posicao.Lucro).toFixed(2) : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="warning">Nenhum trade encontrado para este ativo.</Alert>
          )}
        </Modal.Body>
        <Modal.Footer className={isDarkMode ? 'modal-footer-dark' : ''}>
          <Button variant={isDarkMode ? 'secondary' : 'primary'} onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// Funções para preparar os dados dos gráficos com verificações
const getProfitLossData = (data) => {
  return [
    {
      name: 'Lucro Bruto',
      value: data.lucroBruto,
      fill: '#82ca9d',
    },
    {
      name: 'Perda Bruta',
      value: data.perdaBruta,
      fill: '#ff7300',
    },
    {
      name: 'Lucro Líquido Total',
      value: data.lucroLiquidoTotal,
      fill: '#8884d8',
    },
  ];
};

const getTransactionsPieData = (data) => {
  const negocLucro = data.resultadoNegociacoescomLucroPercentOfTotal;
  const negocPerda = data.resultadoNegociacoescomPerdaPercentOfTotal;

  const negocLucroValue = negocLucro
    ? parseFloat(negocLucro.replace('%', '')) || 0
    : 0;
  const negocPerdaValue = negocPerda
    ? parseFloat(negocPerda.replace('%', '')) || 0
    : 0;

  return [
    { name: 'Negociações com Lucro', value: negocLucroValue, fill: '#4bc0c0' },
    { name: 'Negociações com Perda', value: negocPerdaValue, fill: '#ff9f40' },
  ];
};

export default StatisticsView;
