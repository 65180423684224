import React from 'react';
import Navegacao from './components/Navegacao';

function App() {
  return (
    <Navegacao />
  );
}

export default App;
