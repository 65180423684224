import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Table, Alert, Row, Col } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';

function MovingAvarage() {
  const [deals, setDeals] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [movingAveragePeriod, setMovingAveragePeriod] = useState(40);
  const [chartData, setChartData] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [filteredMetrics, setFilteredMetrics] = useState({
    totalProfit: 'R$ 0.00',
    totalLoss: 'R$ 0.00',
    profitFactor: 'N/A',
    winRate: 'N/A',
    totalTrades: 0,
    winningTrades: 0,
    losingTrades: 0,
    averageTradesBeforeDrop: 'N/A', // Nova métrica
    averageTimeBeforeDrop: 'N/A',    // Nova métrica
  });

  // Novos estados para as métricas de desempenho
  const [averagePerformanceByDay, setAveragePerformanceByDay] = useState([]);
  const [averagePerformanceByMonth, setAveragePerformanceByMonth] = useState([]);
  const [averagePerformanceByWeek, setAveragePerformanceByWeek] = useState([]);
  const [averagePerformanceByYear, setAveragePerformanceByYear] = useState([]);

  // Estados para as novas métricas de performance esperada
  const [expectedPerformanceWeek, setExpectedPerformanceWeek] = useState('N/A');
  const [expectedPerformanceMonth, setExpectedPerformanceMonth] = useState('N/A');
  const [expectedPerformanceYear, setExpectedPerformanceYear] = useState('N/A');

  // Novo estado para controlar a exibição do alerta
  const [isBelowMovingAverage, setIsBelowMovingAverage] = useState(false);

  const expectedColumns = [
    'Time',
    'Deal',
    'Symbol',
    'Type',
    'Direction',
    'Volume',
    'Price',
    'Order',
    'Commission',
    'Swap',
    'Profit',
    'Balance',
    'Comment',
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('Nenhum arquivo selecionado.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      let workbook;

      try {
        workbook = XLSX.read(data, { type: 'array' });
      } catch (err) {
        setError('Erro ao ler o arquivo Excel.');
        return;
      }

      let found = false;
      let jsonData = [];
      let sheetColumns = [];

      for (const sheetName of workbook.SheetNames) {
        const worksheet = workbook.Sheets[sheetName];
        const rawData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });

        for (let i = 0; i < rawData.length; i++) {
          const row = rawData[i];
          const hasAllColumns = expectedColumns.every((col) =>
            row.includes(col)
          );

          if (hasAllColumns) {
            found = true;
            sheetColumns = row;
            jsonData = rawData
              .slice(i + 1)
              .map((dataRow) => {
                const rowObj = {};
                sheetColumns.forEach((col, index) => {
                  rowObj[col] = dataRow[index] || '';
                });
                return rowObj;
              });

            if (jsonData.length > 2) {
              jsonData = jsonData.slice(1, -1);
            } else {
              setError('A tabela não possui dados suficientes para remover o primeiro e o último registro.');
              jsonData = [];
            }

            break;
          }
        }

        if (found) break;
      }

      if (!found) {
        setError(
          'Nenhuma planilha encontrada com as colunas esperadas. Verifique o arquivo e tente novamente.'
        );
        setDeals([]);
        setColumns([]);
        return;
      }

      setColumns(expectedColumns);
      setDeals(jsonData);
      setError('');
    };

    reader.onerror = () => {
      setError('Erro ao ler o arquivo.');
      reader.abort();
    };

    reader.readAsArrayBuffer(file);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  function processChartData() {
    let cumulative = 0;
    const chartDataTemp = [];
    const cumulativeProfits = [];

    deals.forEach((deal, index) => {
      const profit = parseFloat(deal.Profit);
      if (!isNaN(profit)) {
        cumulative += profit;
        cumulativeProfits.push(cumulative);
        chartDataTemp.push({
          time: deal.Time || `Deal ${index + 1}`,
          cumulativeProfit: cumulative,
          profit: profit,
        });
      }
    });

    const movingAverages = [];
    for (let i = 0; i < cumulativeProfits.length; i++) {
      if (i + 1 >= movingAveragePeriod) {
        const windowSlice = cumulativeProfits.slice(i + 1 - movingAveragePeriod, i + 1);
        const sum = windowSlice.reduce((acc, val) => acc + val, 0);
        const average = sum / movingAveragePeriod;
        movingAverages.push(average);
      } else {
        movingAverages.push(null);
      }
    }

    const finalChartData = chartDataTemp.map((dataPoint, index) => ({
      ...dataPoint,
      movingAverage:
        movingAverages[index] !== null
          ? parseFloat(movingAverages[index].toFixed(2))
          : null,
    }));

    return finalChartData;
  };

  const identifyAboveAveragePeriods = (data) => {
    const periods = [];
    let currentPeriod = null;

    for (let i = 0; i < data.length; i++) {
      const point = data[i];
      if (point.cumulativeProfit >= point.movingAverage) {
        if (!currentPeriod) {
          // Iniciar um novo período
          currentPeriod = {
            startIndex: i,
            startTime: parseTime(point.time),
            tradeCount: 1,
          };
        } else {
          // Continuar o período existente
          currentPeriod.tradeCount += 1;
        }
      } else {
        if (currentPeriod) {
          // Encerrar o período atual
          currentPeriod.endIndex = i - 1;
          currentPeriod.endTime = parseTime(data[i - 1].time);
          periods.push(currentPeriod);
          currentPeriod = null;
        }
      }
    }

    // Se o último período ainda estiver aberto
    if (currentPeriod) {
      currentPeriod.endIndex = data.length - 1;
      currentPeriod.endTime = parseTime(data[data.length - 1].time);
      periods.push(currentPeriod);
    }

    return periods;
  };

  const parseTime = (timeStr) => {
    const date = new Date(timeStr);
    return isNaN(date.getTime()) ? null : date;
  };

  const calculateMetrics = (periods, data) => {
    if (periods.length === 0) {
      return {
        averageTradesBeforeDrop: 'N/A',
        averageTimeBeforeDrop: 'N/A',
      };
    }

    let totalTrades = 0;
    let totalDuration = 0; // Em milissegundos
    let validPeriods = 0;

    periods.forEach((period, index) => {
      // Garantir que os tempos foram parseados corretamente
      if (period.startTime && period.endTime) {
        const duration = period.endTime - period.startTime;
        if (duration >= 0) { // Validar a duração
          totalTrades += period.tradeCount;
          totalDuration += duration;
          validPeriods += 1;
        }
      }
    });

    const averageTrades = validPeriods > 0 ? (totalTrades / validPeriods).toFixed(2) : 'N/A';

    const averageDurationMs = validPeriods > 0 ? (totalDuration / validPeriods) : 0;

    const formatDuration = (ms) => {
      const totalSeconds = Math.floor(ms / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${hours}h ${minutes}m ${seconds}s`;
    };

    const averageDuration = validPeriods > 0 ? formatDuration(averageDurationMs) : 'N/A';

    return {
      averageTradesBeforeDrop: averageTrades,
      averageTimeBeforeDrop: averageDuration,
    };
  };

  useEffect(() => {
    const data = processChartData();
    setChartData(data);
  }, [deals, movingAveragePeriod]);

  useEffect(() => {
    const filteredData = dataAboveMovingAverage(chartData);
    setFilteredChartData(filteredData);

    const profits = filteredData
      .map((deal) => parseFloat(deal.profit)) // Usa o lucro individual
      .filter((p) => !isNaN(p));

    const totalProfit = profits.filter((p) => p > 0).reduce((acc, p) => acc + p, 0);
    const totalLoss = profits.filter((p) => p < 0).reduce((acc, p) => acc + p, 0);
    const profitFactor = totalLoss !== 0 ? (totalProfit / Math.abs(totalLoss)).toFixed(2) : 'N/A';

    const totalTrades = profits.length;
    const winningDeals = profits.filter((p) => p > 0).length;
    const losingDeals = profits.filter((p) => p < 0).length;
    const winRate = totalTrades > 0 ? ((winningDeals / totalTrades) * 100).toFixed(2) : 'N/A';

    // Identificar períodos acima da média móvel
    const periods = identifyAboveAveragePeriods(chartData);
    const { averageTradesBeforeDrop, averageTimeBeforeDrop } = calculateMetrics(periods, chartData);

    setFilteredMetrics({
      totalProfit: formatCurrency(totalProfit),
      totalLoss: formatCurrency(Math.abs(totalLoss)),
      profitFactor,
      winRate,
      totalTrades,
      winningTrades: winningDeals,
      losingTrades: losingDeals,
      averageTradesBeforeDrop,
      averageTimeBeforeDrop,
    });
  }, [chartData, movingAveragePeriod]);

  useEffect(() => {
    if (chartData.length > 0) {
      const latest = chartData[chartData.length - 1];
      if (latest.movingAverage !== null && latest.cumulativeProfit < latest.movingAverage) {
        setIsBelowMovingAverage(true);
      } else {
        setIsBelowMovingAverage(false);
      }
    } else {
      setIsBelowMovingAverage(false);
    }
  }, [chartData]);

  const dataAboveMovingAverage = (data) => {
    return data.filter((point) => point.cumulativeProfit >= point.movingAverage);
  };

  const metrics = useMemo(() => {
    const profits = deals
      .map((deal) => parseFloat(deal.Profit))
      .filter((p) => !isNaN(p) && p !== 0);

    const totalProfit = profits.filter((p) => p > 0).reduce((acc, p) => acc + p, 0);
    const totalLoss = profits.filter((p) => p < 0).reduce((acc, p) => acc + p, 0);
    const profitFactor = totalLoss !== 0 ? (totalProfit / Math.abs(totalLoss)).toFixed(2) : 'N/A';

    const totalTrades = profits.length;
    const winningDeals = profits.filter((p) => p > 0).length;
    const losingDeals = profits.filter((p) => p < 0).length;
    const winRate = totalTrades > 0 ? ((winningDeals / totalTrades) * 100).toFixed(2) : 'N/A';

    return {
      totalProfit: formatCurrency(totalProfit),
      totalLoss: formatCurrency(Math.abs(totalLoss)),
      profitFactor,
      winRate,
      totalTrades,
      winningTrades: winningDeals,
      losingTrades: losingDeals,
    };
  }, [deals]);

  // Função para calcular a performance média por dia da semana
  const calculateAveragePerformanceByDay = useMemo(() => {
    const dayMap = {
      0: 'Domingo',
      1: 'Segunda-feira',
      2: 'Terça-feira',
      3: 'Quarta-feira',
      4: 'Quinta-feira',
      5: 'Sexta-feira',
      6: 'Sábado',
    };

    const performance = {};

    deals.forEach((deal) => {
      const date = new Date(deal.Time);
      if (!isNaN(date.getTime())) {
        const day = dayMap[date.getDay()];
        const profit = parseFloat(deal.Profit);
        if (!isNaN(profit)) {
          if (!performance[day]) {
            performance[day] = { totalProfit: 0, count: 0 };
          }
          performance[day].totalProfit += profit;
          performance[day].count += 1;
        }
      }
    });

    const averagePerformance = Object.keys(performance).map((day) => ({
      day,
      averageProfit: parseFloat((performance[day].totalProfit / performance[day].count).toFixed(2)),
    }));

    // Ordenar os dias da semana corretamente
    const orderedDays = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];

    averagePerformance.sort(
      (a, b) => orderedDays.indexOf(a.day) - orderedDays.indexOf(b.day)
    );

    return averagePerformance;
  }, [deals]);

  // Função para calcular a performance média por mês
  const calculateAveragePerformanceByMonth = useMemo(() => {
    const monthMap = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    const performance = {};

    deals.forEach((deal) => {
      const date = new Date(deal.Time);
      if (!isNaN(date.getTime())) {
        const month = monthMap[date.getMonth()];
        const profit = parseFloat(deal.Profit);
        if (!isNaN(profit)) {
          if (!performance[month]) {
            performance[month] = { totalProfit: 0, count: 0 };
          }
          performance[month].totalProfit += profit;
          performance[month].count += 1;
        }
      }
    });

    const averagePerformance = Object.keys(performance).map((month) => ({
      month,
      averageProfit: parseFloat((performance[month].totalProfit / performance[month].count).toFixed(2)),
    }));

    // Ordenar os meses corretamente
    const orderedMonths = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    averagePerformance.sort(
      (a, b) => orderedMonths.indexOf(a.month) - orderedMonths.indexOf(b.month)
    );

    return averagePerformance;
  }, [deals]);

  // Função para obter o número da semana do ano
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Função para calcular a performance média por semana
  const calculateAveragePerformanceByWeek = useMemo(() => {
    const performance = {};

    deals.forEach((deal) => {
      const date = new Date(deal.Time);
      if (!isNaN(date.getTime())) {
        const weekNumber = getWeekNumber(date);
        const year = date.getFullYear();
        const key = `${year}-Semana ${weekNumber}`;
        const profit = parseFloat(deal.Profit);
        if (!isNaN(profit)) {
          if (!performance[key]) {
            performance[key] = { totalProfit: 0, count: 0 };
          }
          performance[key].totalProfit += profit;
          performance[key].count += 1;
        }
      }
    });

    const averagePerformance = Object.keys(performance).map((key) => ({
      week: key,
      averageProfit: parseFloat((performance[key].totalProfit / performance[key].count).toFixed(2)),
    }));

    // Ordenar por ano e semana
    averagePerformance.sort((a, b) => {
      const [yearA, weekA] = a.week.split('-Semana ').map(Number);
      const [yearB, weekB] = b.week.split('-Semana ').map(Number);
      if (yearA !== yearB) return yearA - yearB;
      return weekA - weekB;
    });

    return averagePerformance;
  }, [deals]);

  // Função para calcular a performance média por ano
  const calculateAveragePerformanceByYear = useMemo(() => {
    const performance = {};

    deals.forEach((deal) => {
      const date = new Date(deal.Time);
      if (!isNaN(date.getTime())) {
        const year = date.getFullYear();
        const profit = parseFloat(deal.Profit);
        if (!isNaN(profit)) {
          if (!performance[year]) {
            performance[year] = { totalProfit: 0, count: 0 };
          }
          performance[year].totalProfit += profit;
          performance[year].count += 1;
        }
      }
    });

    const averagePerformance = Object.keys(performance).map((year) => ({
      year,
      averageProfit: parseFloat((performance[year].totalProfit / performance[year].count).toFixed(2)),
    }));

    // Ordenar por ano
    averagePerformance.sort((a, b) => a.year - b.year);

    return averagePerformance;
  }, [deals]);

  // Função para calcular a performance esperada por semana, mês e ano
  const calculateExpectedPerformanceMetrics = useMemo(() => {
    if (deals.length === 0) return {
      expectedPerformanceWeek: 'N/A',
      expectedPerformanceMonth: 'N/A',
      expectedPerformanceYear: 'N/A',
    };

    const startDate = new Date(deals[0].Time);
    const endDate = new Date(deals[deals.length - 1].Time);
    const diffTime = Math.abs(endDate - startDate);

    const diffWeeks = diffTime / (1000 * 60 * 60 * 24 * 7);
    const diffMonths = diffTime / (1000 * 60 * 60 * 24 * 30); // Aproximação de 30 dias por mês
    const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365); // Aproximação de 365 dias por ano

    const totalProfit = deals.reduce((acc, deal) => acc + parseFloat(deal.Profit || 0), 0);
    const averageProfitPerWeek = diffWeeks > 0 ? totalProfit / diffWeeks : 'N/A';
    const averageProfitPerMonth = diffMonths > 0 ? totalProfit / diffMonths : 'N/A';
    const averageProfitPerYear = diffYears > 0 ? totalProfit / diffYears : 'N/A';

    return {
      expectedPerformanceWeek: averageProfitPerWeek !== 'N/A' ? formatCurrency(averageProfitPerWeek) : 'N/A',
      expectedPerformanceMonth: averageProfitPerMonth !== 'N/A' ? formatCurrency(averageProfitPerMonth) : 'N/A',
      expectedPerformanceYear: averageProfitPerYear !== 'N/A' ? formatCurrency(averageProfitPerYear) : 'N/A',
    };
  }, [deals]);

  useEffect(() => {
    const { expectedPerformanceWeek, expectedPerformanceMonth, expectedPerformanceYear } = calculateExpectedPerformanceMetrics;
    setExpectedPerformanceWeek(expectedPerformanceWeek);
    setExpectedPerformanceMonth(expectedPerformanceMonth);
    setExpectedPerformanceYear(expectedPerformanceYear);
  }, [calculateExpectedPerformanceMetrics]);

  useEffect(() => {
    setAveragePerformanceByDay(calculateAveragePerformanceByDay);
  }, [calculateAveragePerformanceByDay]);

  useEffect(() => {
    setAveragePerformanceByMonth(calculateAveragePerformanceByMonth);
  }, [calculateAveragePerformanceByMonth]);

  useEffect(() => {
    setAveragePerformanceByWeek(calculateAveragePerformanceByWeek);
  }, [calculateAveragePerformanceByWeek]);

  useEffect(() => {
    setAveragePerformanceByYear(calculateAveragePerformanceByYear);
  }, [calculateAveragePerformanceByYear]);

  return (
    <Container className="pt-4 text-light">
      <Form.Group data-bs-theme="dark" controlId="formFile" className="mb-3">
        <Form.Label className="text-light">Selecione a planilha de Deals</Form.Label>
        <Form.Control
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          className="bg-dark text-light border-secondary"
        />
      </Form.Group>

      {error && <Alert variant="danger" data-bs-theme="dark">{error}</Alert>}

      {deals.length > 0 && (
        <>
          <Form.Group controlId="movingAveragePeriod" data-bs-theme="dark" className="mb-3">
            <Form.Label>Período da Média Móvel</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={movingAveragePeriod}
              onChange={(e) => setMovingAveragePeriod(Number(e.target.value))}
              placeholder="Insira o período da média móvel"
              className="bg-dark text-light"
            />
          </Form.Group>

          {chartData.length > 0 && (
            <>
              {/* Exibição do alerta condicionalmente */}
              {isBelowMovingAverage && (
                <Alert variant="warning" data-bs-theme="dark">
                  ⚠️ O lucro acumulado está abaixo da média móvel!
                </Alert>
              )}

              <h2>Curva de Capital</h2>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart data={chartData}>
                  <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                  <XAxis dataKey="time" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                    labelStyle={{ color: '#fff' }}
                    itemStyle={{ color: '#fff' }}
                  />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Line
                    type="monotone"
                    dataKey="cumulativeProfit"
                    name="Lucro Acumulado"
                    stroke="#82ca9d"
                    dot={false}
                  />
                  {movingAveragePeriod > 0 && (
                    <Line
                      type="monotone"
                      dataKey="movingAverage"
                      name={`Média Móvel (${movingAveragePeriod})`}
                      stroke="#8884d8"
                      dot={false}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>

              <Row className="mt-4">
                <Col md={3}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Total N Trades</h5>
                    <p>{metrics.totalTrades} | Wins: {metrics.winningTrades} | Losses: {metrics.losingTrades}</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Total Profit</h5>
                    <p>{metrics.totalProfit}</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Profit Factor</h5>
                    <p>{metrics.profitFactor}</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Win Rate</h5>
                    <p>{metrics.winRate}%</p>
                  </div>
                </Col>
              </Row>

              {/* Exibir novas métricas */}
              <Row className="mt-4">
                <Col md={6}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Média de Trades Acima da MA</h5>
                    <p>{filteredMetrics.averageTradesBeforeDrop}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Média de Tempo Acima da MA</h5>
                    <p>{filteredMetrics.averageTimeBeforeDrop}</p>
                  </div>
                </Col>
              </Row>

              {/* Adicionar as novas métricas de performance esperada */}
              <Row className="mt-4">
                <Col md={4}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Performance Esperada Semanal</h5>
                    <p>{expectedPerformanceWeek}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Performance Esperada Mensal</h5>
                    <p>{expectedPerformanceMonth}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Performance Esperada Anual</h5>
                    <p>{expectedPerformanceYear}</p>
                  </div>
                </Col>
              </Row>

              {/* Nova seção de métricas de performance */}
              <h2 className="mt-5">Métricas de Performance</h2>
              <Row className="mt-3">
                <Col md={6}>
                  <h4>Performance Média por Dia da Semana</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={averagePerformanceByDay}>
                      <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                      <XAxis dataKey="day" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                        labelStyle={{ color: '#fff' }}
                        itemStyle={{ color: '#fff' }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="averageProfit" name="Lucro Médio" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
                <Col md={6}>
                  <h4>Performance Média por Mês</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={averagePerformanceByMonth}>
                      <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                      <XAxis dataKey="month" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                        labelStyle={{ color: '#fff' }}
                        itemStyle={{ color: '#fff' }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="averageProfit" name="Lucro Médio" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
              </Row>

              {/* Adicionando as novas métricas de performance média por semana e ano */}
              <Row className="mt-3">
                <Col md={6}>
                  <h4>Performance Média por Semana</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={averagePerformanceByWeek}>
                      <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                      <XAxis dataKey="week" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                        labelStyle={{ color: '#fff' }}
                        itemStyle={{ color: '#fff' }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="averageProfit" name="Lucro Médio" fill="#ffc658" />
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
                <Col md={6}>
                  <h4>Performance Média por Ano</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={averagePerformanceByYear}>
                      <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                      <XAxis dataKey="year" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                        labelStyle={{ color: '#fff' }}
                        itemStyle={{ color: '#fff' }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="averageProfit" name="Lucro Médio" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
              </Row>

              {/* Exibir métricas de performance esperada */}
              <Row className="mt-4">
                <Col md={4}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Performance Esperada Semanal</h5>
                    <p>{expectedPerformanceWeek}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Performance Esperada Mensal</h5>
                    <p>{expectedPerformanceMonth}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="p-3 border bg-dark rounded text-light">
                    <h5>Performance Esperada Anual</h5>
                    <p>{expectedPerformanceYear}</p>
                  </div>
                </Col>
              </Row>

              {filteredChartData.length > 0 && (
                <>
                  <h2 className="mt-5">Trades Acima da Média Móvel</h2>
                  <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={filteredChartData}>
                      <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                      <XAxis dataKey="time" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                        labelStyle={{ color: '#fff' }}
                        itemStyle={{ color: '#fff' }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Line
                        type="monotone"
                        dataKey="cumulativeProfit"
                        name="Lucro Acumulado"
                        stroke="#ffc658"
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>

                  <Row className="mt-4">
                    <Col md={3}>
                      <div className="p-3 border bg-dark rounded text-light">
                        <h5>Total N Trades</h5>
                        <p>{filteredMetrics.totalTrades} | Wins: {filteredMetrics.winningTrades} | Losses: {filteredMetrics.losingTrades}</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="p-3 border bg-dark rounded text-light">
                        <h5>Total Profit</h5>
                        <p>{filteredMetrics.totalProfit}</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="p-3 border bg-dark rounded text-light">
                        <h5>Profit Factor</h5>
                        <p>{filteredMetrics.profitFactor}</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="p-3 border bg-dark rounded text-light">
                        <h5>Win Rate</h5>
                        <p>{filteredMetrics.winRate}%</p>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <h2 className="mt-5">Deals</h2>
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr> 
                </thead>
                <tbody>
                  {deals.slice(-20).map((deal, rowIndex) => (
                    <tr key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <td key={`${rowIndex}-${colIndex}`}>{deal[column]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default MovingAvarage;
