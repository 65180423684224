// Navegacao.jsx
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Row, Col, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import FileSelector from './FileSelector';
import StatisticsView from './StatisticsView/StatisticsView';
import PositionsDetails from './PositionsDetails/PositionsDetails';
import TradesCalendar from './TradesCalendar/TradesCalendar';
import MovingAvarage from './Backtest/movingAvarage';
import Correlation from './Backtest/correlation';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme, setTheme } from '../redux/dataSlice';
import TradingMetrics from './TradingMetrics/TradingMetrics';
import '../index.css';

const Navegacao = () => {
    const [currentView, setCurrentView] = useState('FileSelector');
    const isDarkMode = useSelector((state) => state.data.isDarkMode);
    const dispatch = useDispatch();

    useEffect(() => {
        const storedTheme = localStorage.getItem('fileSelectorTheme');
        if (storedTheme) {
            dispatch(setTheme(storedTheme === 'dark'));
        } else {
            const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            dispatch(setTheme(prefersDark));
        }
        // eslint-disable-next-line
    }, []);

    const handleThemeToggle = () => {
        dispatch(toggleTheme());
    };

    const renderContent = () => {
        switch (currentView) {
            case 'FileSelector':
                return <FileSelector />;
            case 'StatisticsView':
                return <StatisticsView />;
            case 'PositionsDetails':
                return <PositionsDetails />;
            case 'TradesCalendar':
                return <TradesCalendar />;
            case 'TradingMetrics':
                return <TradingMetrics />;
            case 'Correlation':
                return <Correlation />;
            case 'MovingAvarage':
                return <MovingAvarage />;
            default:
                return <FileSelector />;
        }
    };

    return (
        <div className={isDarkMode ? 'bg-dark text-white vh-100' : 'bg-light text-dark vh-100'}>
            <Navbar
                className={`bg-${isDarkMode ? 'dark' : 'light'} ${isDarkMode ? 'custom-navbar-dark' : 'custom-navbar'}`}
                variant={isDarkMode ? 'dark' : 'light'}
                expand="lg"
            >
                <Container fluid>
                    <Navbar.Brand>
                        <img
                            src={process.env.PUBLIC_URL + '/warrior_logo.png'}
                            alt="Logo"
                            height="40"
                            className="d-inline-block align-top"
                            />
                    </Navbar.Brand>
                    <Nav className="ms-auto">
                    </Nav>
                </Container>
            </Navbar>

            <Container fluid>
                            
                <Row>
                    {/* Sidebar */}
                    <Col
                        xs={12}
                        md={3}
                        lg={2}
                        className={isDarkMode ? 'custom-sidebar-dark text-white' : 'custom-sidebar text-white'}
                        >
                        <p>Análise de Histórico</p>
                        <ListGroup variant="flush">
                            <ListGroup.Item
                                action
                                active={currentView === 'FileSelector'}
                                onClick={() => setCurrentView('FileSelector')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i className="bi bi-gear"></i> Configuração
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                active={currentView === 'StatisticsView'}
                                onClick={() => setCurrentView('StatisticsView')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i className="bi bi-graph-up-arrow"></i> Gráficos
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                active={currentView === 'PositionsDetails'}
                                onClick={() => setCurrentView('PositionsDetails')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i className="bi bi-clipboard-data"></i> Detalhes das Posições
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                active={currentView === 'TradesCalendar'}
                                onClick={() => setCurrentView('TradesCalendar')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i className="bi bi-calendar-week"></i> Calendário de Trades
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                active={currentView === 'TradingMetrics'}
                                onClick={() => setCurrentView('TradingMetrics')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i className="bi bi-bar-chart"></i> Métricas de Trading
                            </ListGroup.Item>

                            <hr />
                            <p>Análise de Backtest</p>

                            <ListGroup.Item
                                action
                                active={currentView === 'MovingAvarage'}
                                onClick={() => setCurrentView('MovingAvarage')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i class="bi bi-calendar2-week"></i> Backtest MA
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                active={currentView === 'Correlation'}
                                onClick={() => setCurrentView('Correlation')}
                                className={isDarkMode ? 'text-white sidebar-btn' : 'text-white sidebar-btn'}
                            >
                                <i class="bi bi-calendar2-week"></i> Backtest CR
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>

                    {/* Conteúdo Principal */}
                    <Col xs={12} md={9} lg={9} className="main-content">
                        {renderContent()}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Navegacao;
